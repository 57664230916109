import { ReinforcementType } from '@/utils/constants/actions';

// command_type determines what property from the toSend object to read from (what action the troops were selected for)
// reinforcement_control_level determines the list of reinforcements that are valid for the wanted action
export function addTroopsToPayload({ payload, troops, command_type, reinforcement_filters }) {
    let OwnedTroops = null;
    const Reinforcements = [];
    const ReinforcementIds = [];

    troops.forEach(troop => {
        if (troop.getNumberToSend(command_type) > 0) {
            if (!OwnedTroops) {
                OwnedTroops = {
                    troopsName: [troop.getName()],
                    numOfTroops: [troop.getNumberToSend(command_type)],
                    owned: true,
                };
            } else {
                OwnedTroops.troopsName.push(troop.getName());
                OwnedTroops.numOfTroops.push(troop.getNumberToSend(command_type));
            }
        }

        troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
            if (reinforcement.getNumberToSend(command_type) > 0) {
                const ReinforcementIndex = ReinforcementIds.findIndex(
                    reinforcement_id => reinforcement_id === reinforcement.getOriginalId(),
                );
                if (ReinforcementIndex === -1) {
                    Reinforcements.push({
                        troopsName: [reinforcement.getTroopName()],
                        numOfTroops: [reinforcement.getNumberToSend(command_type)],
                        owned: false,
                    });

                    ReinforcementIds.push(reinforcement.getOriginalId());
                } else {
                    const FoundReinforcement = Reinforcements[ReinforcementIndex];

                    FoundReinforcement.troopsName.push(reinforcement.getTroopName());
                    FoundReinforcement.numOfTroops.push(reinforcement.getNumberToSend(command_type));
                }
            }
        });
    });

    const FinalTroopsArray = OwnedTroops ? [...Reinforcements, OwnedTroops] : Reinforcements;

    payload.troops = FinalTroopsArray;

    if (ReinforcementIds.length > 0) {
        payload.reinforcements = ReinforcementIds;
    }
}

export function addReinforcementPermissionsToPayload(payload, reinforcement_type) {
    switch (reinforcement_type) {
        case ReinforcementType.STATION: {
            payload.targetCanControl = false;
            payload.targetCanPassControl = false;
            break;
        }
        case ReinforcementType.PARTIAL_CONTROL: {
            payload.targetCanControl = true;
            payload.targetCanPassControl = false;
            break;
        }
        case ReinforcementType.FULL_CONTROL: {
            payload.targetCanControl = true;
            payload.targetCanPassControl = true;
            break;
        }
    }
}

export function getStringifiedTroops(troops) {
    let FinalString = `[`;

    troops.forEach((troop, index) => {
        FinalString += `{troopsName: ${JSON.stringify(troop.troopsName)}, numOfTroops: [${troop.numOfTroops}], owned: ${
            troop.owned
        }}`;
        FinalString += index < troops.length - 1 ? ', ' : ']';
    });

    return FinalString;
}
