import Collection from '@/classes/generic/Collection';
import ToSendOnAction from '@/classes/troops/ToSendOnAction';
import { getTroopType } from '@/utils/functions/troopUtils';
import { ReinforcementControlLevel, ReinforcementFilterType } from '@/utils/constants/troops';
import { ReinforcementCategory } from '@/utils/constants/actions';

export default class Troop {
    _originalData = null;

    _name = '';
    _type = null;

    _allReinforcements = new Collection([]);

    // includes STATIONED reinforcements
    // used for calling back
    _receivedReinforcements = new Collection([]);

    // usable for any action, except Reinforcement
    _partiallyControlledReinforcements = new Collection([]);

    // usable for any action, including Reinforcement
    _fullyControlledReinforcements = new Collection([]);

    // includes BOTH partially controlled AND fully controlled reinforcements
    // by default, this is the control level applied when selecting troops for most actions
    _controlledReinforcements = new Collection([]);

    _sentReinforcements = new Collection([]);

    // data from foreignSpies
    // treated as reinforcements as they behave almost identically
    _spiesOnMission = new Collection([]);

    _toSend = new ToSendOnAction();

    constructor({ troop, received_reinforcements, sent_reinforcements, spies_on_mission }) {
        this._originalData = troop;

        this._name = troop.name;
        this._type = getTroopType(troop.name);

        received_reinforcements.forEach(reinforcement => this.distributeReceivedReinforcement(reinforcement));
        sent_reinforcements.forEach(reinforcement => this.addSentReinforcement(reinforcement));
        spies_on_mission.forEach(spies_object => this.addSpiesOnMission(spies_object));
    }

    distributeReceivedReinforcement(reinforcement) {
        this._allReinforcements.addItem(reinforcement);

        this._receivedReinforcements.addItem(reinforcement);

        if (reinforcement.isPartiallyControlled() && reinforcement.isUsable()) {
            this._partiallyControlledReinforcements.addItem(reinforcement);
            this._controlledReinforcements.addItem(reinforcement);
        }

        if (reinforcement.isFullyControlled() && reinforcement.isUsable()) {
            this._fullyControlledReinforcements.addItem(reinforcement);
            this._controlledReinforcements.addItem(reinforcement);
        }
    }

    addSentReinforcement(reinforcement) {
        this._allReinforcements.addItem(reinforcement);
        this._sentReinforcements.addItem(reinforcement);
    }

    addSpiesOnMission(spies_object) {
        // might cause problems because of incompatible interfaces
        // temporary solution is to only use ReinforcementCategory.ALL filter for setting and getting troops to send
        this._allReinforcements.addItem(spies_object);
        this._spiesOnMission.addItem(spies_object)
    }

    getName() {
        return this._name;
    }

    getType() {
        return this._type;
    }

    getReinforcements({ category, filter_type, filter_value }) {
        switch (category) {
            case ReinforcementCategory.SENT:
                switch (filter_type) {
                    case ReinforcementFilterType.NONE:
                        return this._getSentReinforcements();
                    case ReinforcementFilterType.DESTINATION:
                        return this._getSentReinforcementsByDestination(filter_value);
                }
                break;
            case ReinforcementCategory.RECEIVED:
                switch (filter_type) {
                    case ReinforcementFilterType.NONE:
                        return this._getReceivedReinforcements();
                    case ReinforcementFilterType.CONTROL_LEVEL:
                        return this._getReceivedReinforcementsByControlLevel(filter_value);
                    case ReinforcementFilterType.EARLIEST_ORIGIN:
                        return this._getReceivedReinforcementsByEarliestOrigin(filter_value);
                    case ReinforcementFilterType.LATEST_ORIGIN:
                        return this._getReceivedReinforcementsByLatestOrigin(filter_value);
                }
                break;
            case ReinforcementCategory.ALL:
                return this._getAllReinforcements();
            case ReinforcementCategory.SPIES:
                return this._getSpiesOnMission();
        }
    }

    _getSentReinforcements() {
        return this._sentReinforcements.getItems();
    }

    _getSentReinforcementsByDestination(city_id) {
        const Reinforcements = new Collection([]);

        this._getSentReinforcements().forEach(reinforcement => {
            if (reinforcement.isSentToCity(city_id)) {
                Reinforcements.addItem(reinforcement);
            }
        });

        return Reinforcements.getItems();
    }

    _getReceivedReinforcements() {
        return this._receivedReinforcements.getItems();
    }

    // get only relevant reinforcements for the control level you want to give troops sent on reinforcement mission
    _getReceivedReinforcementsByControlLevel(control_level) {
        switch (control_level) {
            case ReinforcementControlLevel.PARTIAL:
                return this._partiallyControlledReinforcements.getItems();
            case ReinforcementControlLevel.FULL:
                return this._fullyControlledReinforcements.getItems();
            case ReinforcementControlLevel.PARTIAL_OR_FULL:
                return this._controlledReinforcements.getItems();
        }
    }

    // get only reinforcements that are originally from provided city_id
    _getReceivedReinforcementsByEarliestOrigin(city_id) {
        const Reinforcements = new Collection([]);

        this._getReceivedReinforcements().forEach(reinforcement => {
            if (reinforcement.isOriginallyFromCity(city_id)) {
                Reinforcements.addItem(reinforcement);
            }
        });

        return Reinforcements.getItems();
    }

    // get only reinforcements where the latest origin (fromCity._id) is the provided city_id
    _getReceivedReinforcementsByLatestOrigin(city_id) {
        const Reinforcements = new Collection([]);

        this._getReceivedReinforcements().forEach(reinforcement => {
            if (reinforcement.isFromCity(city_id)) {
                Reinforcements.addItem(reinforcement);
            }
        });

        return Reinforcements.getItems();
    }

    _getAllReinforcements() {
        return this._allReinforcements.getItems();
    }

    _getSpiesOnMission() {
        return this._spiesOnMission.getItems();
    }

    getDescription() {
        return this._originalData.description;
    }

    getRequirements() {
        return this._originalData.requirements;
    }

    getAntiAirDefense() {
        return this._originalData.antiAirDefense;
    }

    getAntiArtilleryAndTankDefense() {
        return this._originalData.antiArtilleryAndTankDefense;
    }

    getAntiInfantryDefense() {
        return this._originalData.antiInfantryDefense;
    }

    getAttack() {
        return this._originalData.attack;
    }

    getCarryCapacity() {
        return this._originalData.carryCapacity;
    }

    getMovementSpeed() {
        return this._originalData.movementSpeed;
    }

    getOilConsumption() {
        return this._originalData.oilConsumption;
    }

    getRationsConsumption() {
        return this._originalData.rationsConsumption;
    }

    getAluminumCost() {
        return this._originalData.aluminum;
    }

    getOilCost() {
        return this._originalData.oil;
    }

    getMetalCost() {
        return this._originalData.metal;
    }

    getPopulationCost() {
        return this._originalData.population;
    }

    getRecruitmentTime() {
        return this._originalData.time;
    }

    getTroopCount() {
        return this._originalData.numOfTroops;
    }

    decreaseTroopCount(number) {
        this._originalData.numOfTroops -= number;
    }

    increaseTroopCount(number) {
        this._originalData.numOfTroops += number;
    }

    getReinforcementCount() {
        return this._originalData.controlledReinforcements;
    }

    getNumberToSend(command_type) {
        return this._toSend[command_type];
    }

    setNumberToSend(command_type, number) {
        this._toSend[command_type] = number;
    }
}
