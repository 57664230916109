<template>
    <div
        v-dragscroll
        id="world-map-wrapper"
        @scroll="throttle(emitScrollCoords, throttleTimerCoordsEmit, 16)"
        @touchstart="stopCoordsEmit"
        @mousedown="stopCoordsEmit"
        :style="`
			transform: scale(${mapScaleFactor});
			width: calc(${(1 / mapScaleFactor) * 100}vw);
			margin-left: calc(${(100 - (1 / mapScaleFactor) * 100) / 2}vw);
			height: calc(${(1 / mapScaleFactor) * 100}vh);
			margin-top: calc(${(100 - (1 / mapScaleFactor) * 100) / 2}vh);
		`"
    >
        <div
            class="world-map"
            :style="`
				height: ${WORLD_MAP_HEIGHT}px;
				width: ${WORLD_MAP_WIDTH}px;
			`"
        >
            <template v-for="row of visibleTiles" :key="row.key">
                <MapTile
                    v-for="tile of row.tiles"
                    :key="tile.tile"
                    :tile="tile"
                    :centered-on-city="centeredOnCity"
                    :map-scale-factor="mapScaleFactor"
                    :current-city-id="currentCityId"
                />
            </template>
            <MapArrowsLayer
                :map-arrows="currentCityMapArrows"
                :map-actions="mapActions"
                @scroll-to-target="(targetId, targetCoords) => scrollToCity(targetId, targetCoords, 'smooth')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { paramCase } from 'change-case';
import {
    WORLD_MAP_WIDTH,
    WORLD_MAP_HEIGHT,
    MAP_TILES_VERTICALLY,
    MAP_TILES_HORIZONTALLY,
    MAP_TILE_WIDTH,
    MAP_TILE_HEIGHT,
    CITY_TILE_WIDTH,
    CITY_TILE_HEIGHT,
} from '@/utils/constants/map';
import MapTile from '@/components/WorldMap/MapTile';
import MapArrowsLayer from '@/components/WorldMap/MapArrowsLayer';

export default {
    name: 'WorldMap',
    components: { MapArrowsLayer, MapTile },
    props: {
        allCities: {
            type: Array,
            default: () => [],
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        isMapCentered: {
            type: Boolean,
            default: false,
        },
        currentCityId: {
            type: String,
            default: '',
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
        worldMapScrollCoordsFromMinimap: {
            type: Object,
            default: () => {},
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        currentCityMapArrows() {
            const AllArrows = this.getMapArrows();
            const CurrentCityMapArrows = new Set();

            AllArrows.getItems().forEach(arrows_pair => {
                if (arrows_pair.ownCity._id === this.currentCityId) {
                    CurrentCityMapArrows.add(arrows_pair);
                }
            });

            console.log('CurrentCityMapArrows:::', CurrentCityMapArrows);
            return CurrentCityMapArrows;
        },
    },
    data() {
        return {
            WORLD_MAP_WIDTH,
            WORLD_MAP_HEIGHT,
            MAP_TILES_VERTICALLY,
            MAP_TILES_HORIZONTALLY,
            MAP_TILE_WIDTH,
            MAP_TILE_HEIGHT,

            mapMatrix: [],
            visibleTiles: [],
            visibleIndexes: {
                topIndex: null,
                bottomIndex: null,
                leftIndex: null,
                rightIndex: null,
            },

            centeredOnCity: null,
            worldMap: null,
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            coordsEmitStopped: false,
            // throttle timers are objects so that they can be passed by reference
            // to throttle function, rather than by value
            throttleTimerCoordsEmit: {
                timer: null,
            },
            throttleTimerTileVisibility: {
                timer: null,
            },
        };
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        this.mapMatrix = this.getMapMatrix();
        this.$nextTick(() => {
            this.worldMap = document.getElementById('world-map-wrapper');
        });

        window.addEventListener('resize', this.onResize);
        this.$nextTick(() => {
            this.scrollToCity(this.currentCityId, this.currentCityCoords, 'instant');
        });
    },
    watch: {
        mapScaleFactor: {
            handler() {
                this.setVisibleTiles();
                // stop view from sliding when zooming out on map border
                this.stopCoordsEmit();
                this.allowCoordsEmit();
            },
        },
        isMapCentered: {
            handler(isCentered) {
                if (isCentered) {
                    this.scrollToCity(this.currentCityId, this.currentCityCoords, 'smooth');
                }
            },
            deep: true,
        },
        worldMapScrollCoordsFromMinimap: {
            handler() {
                if (!this.coordsEmitStopped) {
                    if (this.worldMap !== undefined) {
                        this.worldMap.scrollLeft = this.worldMapScrollCoordsFromMinimap.x;
                        this.worldMap.scrollTop = this.worldMapScrollCoordsFromMinimap.y;
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapGetters(['getMapMatrix', 'getMapArrows']),
        paramCase,

        setVisibleTiles() {
            const FilteredMapMatrix = [];

            const CurrentIndexes = this.getVisibleIndexes();

            if (this.areIndexesTheSame(CurrentIndexes)) {
                this.setVisibleIndexes(CurrentIndexes);
                return;
            }

            for (let i = CurrentIndexes.topIndex; i <= CurrentIndexes.bottomIndex; i++) {
                const RowObject = {
                    tiles: [],
                    key: i,
                };
                for (let j = CurrentIndexes.leftIndex; j <= CurrentIndexes.rightIndex; j++) {
                    RowObject.tiles.push(this.mapMatrix[i][j]);
                }
                FilteredMapMatrix.push(RowObject);
            }

            this.setVisibleIndexes(CurrentIndexes);
            this.visibleTiles = FilteredMapMatrix;
        },

        getVisibleIndexes() {
            const topIndex = Math.max(Math.floor(this.worldMap.scrollTop / MAP_TILE_HEIGHT) - 1, 0);

            const bottomIndex = Math.min(
                Math.floor((this.worldMap.scrollTop + this.windowSize.height / this.mapScaleFactor) / MAP_TILE_HEIGHT) + 1,
                MAP_TILES_VERTICALLY - 1,
            );

            const leftIndex = Math.max(Math.floor(this.worldMap.scrollLeft / MAP_TILE_WIDTH) - 1, 0);

            const rightIndex = Math.min(
                Math.floor((this.worldMap.scrollLeft + this.windowSize.width / this.mapScaleFactor) / MAP_TILE_WIDTH) + 1,
                MAP_TILES_HORIZONTALLY - 1,
            );

            return {
                topIndex,
                bottomIndex,
                leftIndex,
                rightIndex,
            };
        },

        setVisibleIndexes(indexes) {
            this.visibleIndexes.topIndex = indexes.topIndex;
            this.visibleIndexes.bottomIndex = indexes.bottomIndex;
            this.visibleIndexes.leftIndex = indexes.leftIndex;
            this.visibleIndexes.rightIndex = indexes.rightIndex;
        },

        areIndexesTheSame(indexes) {
            return (
                this.visibleIndexes.topIndex === indexes.topIndex &&
                this.visibleIndexes.bottomIndex === indexes.bottomIndex &&
                this.visibleIndexes.leftIndex === indexes.leftIndex &&
                this.visibleIndexes.rightIndex === indexes.rightIndex
            );
        },

        throttle(func, timer, delay) {
            if (timer.timer === null) {
                func();
                timer.timer = setTimeout(() => {
                    timer.timer = null;
                    func();
                }, delay);
            }
        },

        scrollToCity(city_id, coords, behavior) {
            if (behavior === 'smooth') {
                this.centeredOnCity = city_id;
                setTimeout(() => {
                    this.centeredOnCity = null;
                }, 2000);
            }
            this.stopCoordsEmit();
            document.getElementById('world-map-wrapper').scrollTo({
                left: this.calculateCityCenterX(coords.x),
                top: this.calculateCityCenterY(coords.y),
                behavior: behavior,
            });
            setTimeout(() => {
                this.allowCoordsEmit();
            }, 1000);
        },

        calculateCityCenterX(x) {
            return x * CITY_TILE_WIDTH + CITY_TILE_WIDTH / 2 - this.windowSize.width / this.mapScaleFactor / 2;
        },

        calculateCityCenterY(y) {
            return y * CITY_TILE_HEIGHT + CITY_TILE_HEIGHT / 2 - this.windowSize.height / this.mapScaleFactor / 2;
        },

        stopCoordsEmit() {
            this.coordsEmitStopped = true;
            window.addEventListener('mouseup', this.allowCoordsEmit);
            window.addEventListener('touchend', this.allowCoordsEmit);
        },

        allowCoordsEmit() {
            setTimeout(() => {
                this.coordsEmitStopped = false;
                window.removeEventListener('mouseup', this.allowCoordsEmit);
                window.removeEventListener('touchend', this.allowCoordsEmit);
            }, 100);
        },

        onResize() {
            this.windowSize = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            this.$emit('window-resize', this.windowSize);
        },

        emitScrollCoords() {
            this.throttle(this.setVisibleTiles, this.throttleTimerTileVisibility, 100);
            this.$emit('world-map-scroll', {
                x: this.worldMap.scrollLeft,
                y: this.worldMap.scrollTop,
            });
        },
    },
};
</script>

<style scoped>
#world-map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    /*filter: brightness(0.15);*/
}

.world-map {
    position: relative;
    overflow: hidden;
    background: #79664b;
    user-select: none;
    cursor: move;
}
</style>
