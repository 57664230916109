<template>
    <div class="command-group">
        <div class="map-actions-city-icon-wrapper">
            <!-- TO DO: add dynamic city image -->
            <img src="../assets/map_tiles/city_2_mobile.avif" class="map-actions-city-icon" />
        </div>
        <span class="command-target-wrapper">
            <span @click="openMapActionModal(map_action)">{{ command.actionDescription }}</span>
            <span class="command-target">{{ command.target?.name }}</span>
        </span>
        <span class="command-duration">{{ command.progressTracking.dynamicDurationString }}</span>
        <span class="command-finish">{{ command.originalData.arrivalDateToShow || command.originalData.arrivalDate }}</span>
    </div>
</template>

<script>
export default {
    name: 'CommandRowCityUI',
    props: {
        command: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped>
.command-target-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 8rem;
}

.command-target {
    color: burlywood;
    cursor: pointer;
    width: 8rem;
}

.command-target:hover {
    color: cornflowerblue;
}

.command-group {
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ghostwhite;
    display: flex;
    gap: 0.75rem;
    align-items: center;
}

.map-actions-city-icon-wrapper {
    min-width: 3.5rem;
}

.map-actions-city-icon {
    height: 2rem;
}

.command-duration {
    width: 5.5rem;
}

.command-finish {
    width: 8.75rem;
}

@media screen and (max-width: 660px) {
    .command-finish {
        width: 5rem;
    }
}

@media screen and (max-width: 560px) {
    .command-group {
        gap: 0.5rem;
    }
    .command-target-wrapper {
        display: unset;
        flex-direction: unset;
        min-width: unset;
        max-width: 6rem;
    }
}

@media screen and (max-width: 450px) {
    .command-finish {
        display: none;
    }
    .command-group {
        font-size: 0.625rem;
    }
    .map-actions-city-icon-wrapper {
        min-width: 2.5rem;
    }
    .map-actions-city-icon {
        height: 1.5rem;
    }
}
</style>
