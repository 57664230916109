import axios from 'axios';
import city from '@/store/modules/city';

export default {
    async register({ commit }, userCredentials) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/register`, { userCredentials }, { withCredentials: true })
            .then(response => {
                if (!response.data) {
                    commit('setEmailValid', false);
                    return;
                }
                commit('setEmailValid', true);
                city.state.id = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    },

    async logout({ commit }) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/logout`, {}, { withCredentials: true })
            .then(response => {
                if (!response.data) {
                    commit('setLogoutState', false);
                    return;
                }
                commit('setLogoutState', true);
            })
            .catch(e => {
                console.log(e);
            });
    },

    async emailAlreadyExists({ commit }, { email }) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/uniqueEmail`, {
                email,
            })
            .then(response => {
                commit('setUniqueEmailBool', response.data);
            });
    },

    async usernameAlreadyExists({ commit }, { username }) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/uniqueUsername`, {
                username,
            })
            .then(response => {
                commit('setUniqueUsernameBool', response.data);
            });
    },

    async login({ commit }, userCredentials) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/login`, userCredentials, { withCredentials: true })
            .then(response => {
                if (!response.data) {
                    commit('setAreCredentialsValid', false);
                } else {
                    commit('setAreCredentialsValid', true);
                    city.state.id = response.data;
                }
            })
            .catch(e => {
                console.log(e);
            });
    },

    async checkForToken({ commit }) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/checkForToken`, {}, { withCredentials: true })
            .then(response => {
                if (!response.data) {
                    commit('setAreCredentialsValid', false);
                } else {
                    city.state.id = response.data;
                    commit('setAreCredentialsValid', true);
                }
            })
            .catch(e => {
                console.log(e);
            });
    },

    async verifyAccount({ commit }, confirmationToken) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/confirmAccount`, confirmationToken)
            .then(response => {
                commit('setConfirmAccountResponse', response.data);
            })
            .catch(e => {
                console.log(e);
            });
    },

    async sendCredentialsEmail({ commit }, { email, username, password, both }) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/recoverCredentials`, { email, username, password, both })
            .then(response => {
                commit('setCredentialsRecoveryResponse', response.data);
            })
            .catch(e => {
                console.log(e);
            });
    },

    async changePassword({ commit }, { credentialsToken, password }) {
        await axios
            .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/changepass`, { credentialsToken, password })
            .then(response => {
                commit('setChangePasswordResponse', response.data);
            })
            .catch(e => {
                console.log(e);
            });
    },

    storeCredentials({ commit }, { email, password, username }) {
        commit('setCredentials', { email, password, username });
    },

    updateAttemptedLogin({ commit }, data) {
        commit('setAttemptedLogin', data);
    },
};
