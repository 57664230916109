import { createStore } from 'vuex';
import authentication from './modules/authentication/index';
import city from './modules/city';

export default createStore({
    modules: {
        authentication,
        city,
    },
});
