export default {
    setCredentials(state, data) {
        state.email = data.email;
        state.password = data.password;
        state.username = data.username;
    },

    setUniqueEmailBool(state, data) {
        state.isUniqueEmail = data;
    },

    setUniqueUsernameBool(state, data) {
        state.isUniqueUsername = data;
    },

    setAreCredentialsValid(state, data) {
        state.areCredentialsValid = data;
    },

    setAttemptedLogin(state, data) {
        state.attemptedLogin = data;
    },

    setEmailValid(state, data) {
        state.emailValid = data;
    },

    setLogoutState(state, data) {
        state.successfulLogout = data;
    },

    setConfirmAccountResponse(state, data) {
        state.confirmAccountResponse = data;
    },
    setCredentialsRecoveryResponse(state, data) {
        state.credentialsRecoveryResponse = data;
    },
    setChangePasswordResponse(state, data) {
        state.changePasswordResponse = data;
    },
};
