<template>
    <div class="arrows-wrapper">
        <OutgoingMapArrow
            v-if="outgoingArrow"
            :arrow="outgoingArrow"
            :angle-between-cities="angleBetweenCities"
            @scroll-to-target="(targetId, targetCoords) => $emit('scroll-to-target', targetId, targetCoords)"
        />
        <IncomingMapArrow
            v-if="incomingArrow"
            :arrow="incomingArrow"
            :angle-between-cities="angleBetweenCities"
            @scroll-to-target="(targetId, targetCoords) => $emit('scroll-to-target', targetId, targetCoords)"
        />
    </div>
</template>

<script>
import MapArrowsPair from '@/classes/mapArrows/MapArrowsPair';
import OutgoingMapArrow from '@/components/WorldMap/OutgoingMapArrow';
import {
    getAngleBetweenCities,
    getCityCenterX,
    getCityCenterY,
    getDistanceBetweenCities,
} from '@/utils/functions/mapArrowsUtils';
import { MapArrowDirection } from '@/utils/constants/actions';
import IncomingMapArrow from '@/components/WorldMap/IncomingMapArrow';

export default {
    name: 'MapArrowsPair',
    components: { IncomingMapArrow, OutgoingMapArrow },
    props: {
        arrowsPair: {
            type: MapArrowsPair,
            default: () => new MapArrowsPair([]),
        },
    },
    computed: {
        outgoingArrow() {
            let Arrow = null;
            this.arrowsPair.getItems().forEach(arrow => {
                if (arrow.direction === MapArrowDirection.OUTGOING) {
                    Arrow = arrow;
                }
            });
            console.log('^^^^^^^', Arrow);
            return Arrow;
        },
        incomingArrow() {
            let Arrow = null;
            this.arrowsPair.getItems().forEach(arrow => {
                if (arrow.direction === MapArrowDirection.INCOMING) {
                    Arrow = arrow;
                }
            });
            return Arrow;
        },
        ownX() {
            console.log('ownX', getCityCenterX(this.arrowsPair.ownCity.x));
            return getCityCenterX(this.arrowsPair.ownCity.x);
        },
        ownY() {
            console.log('ownY', getCityCenterY(this.arrowsPair.ownCity.y));
            return getCityCenterY(this.arrowsPair.ownCity.y);
        },
        targetX() {
            return getCityCenterX(this.arrowsPair.targetCity.x);
        },
        targetY() {
            return getCityCenterY(this.arrowsPair.targetCity.y);
        },
        distanceBetweenCities() {
            return getDistanceBetweenCities(this.ownX, this.ownY, this.targetX, this.targetY);
        },
        angleBetweenCities() {
            return getAngleBetweenCities(this.ownX, this.ownY, this.targetX, this.targetY);
        },
		absoluteAngleBetweenCities() {
			return 360 - Math.floor(Math.abs(this.angleBetweenCities));
		}
    },
    emits: ['scroll-to-target'],
};
</script>

<style scoped>
.arrows-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform-origin: left center;
    left: v-bind(ownX + 'px');
    top: v-bind(ownY + 'px');
    width: v-bind(distanceBetweenCities + 'px');
    transform: rotateZ(v-bind(angleBetweenCities + 'deg'));
	z-index: v-bind(absoluteAngleBetweenCities);
}
</style>
