export default class Troops {
    countedByTroop = null;

    constructor(original_action) {
        this.countedByTroop = this.buildCountedByTroopObject(original_action.troops);
    }

    buildCountedByTroopObject(troops) {
        const TroopsObject = {};

        // each object in the original "troops" array represents an origin; one is for owned troops,
        // then one for each different reinforcement the troops originate from
        troops?.forEach(origin => {
            origin.troopsName?.forEach((troop_name, index) => {
                const CurrentNumberOfTroops = origin.numOfTroops[index];
                const isAlreadyInObject = !!TroopsObject[troop_name];
                if (isAlreadyInObject) {
                    TroopsObject[troop_name] += CurrentNumberOfTroops;
                } else {
                    TroopsObject[troop_name] = CurrentNumberOfTroops;
                }
            });
        });

		return TroopsObject;
    }
}
