import CollectionItem from '@/classes/generic/CollectionItem';
import ToSendOnAction from '@/classes/troops/ToSendOnAction';
import { v4 as uuidv4 } from 'uuid';

export default class SpiesOnMission extends CollectionItem {
    _originalForeignSpiesObject = null;
    _originalTroopsObject = null;

    _id = null;
    _toSend = new ToSendOnAction();

    constructor(foreign_spies_object, troops_object) {
        super();
        this._originalForeignSpiesObject = foreign_spies_object;
        this._originalTroopsObject = troops_object;
        this._id = uuidv4();
    }

    getId() {
        return this._id;
    }

    getReinforcementId() {
        return this._originalForeignSpiesObject?.lastOrigin?.reinforcement;
    }

    getOriginUserName() {
        return this._originalForeignSpiesObject?.fromUser;
    }

    getOriginCityName() {
        return this._originalForeignSpiesObject?.fromCity;
    }

    getDestinationCityId() {
        return this._originalForeignSpiesObject?.toCity;
    }

    getDestinationCityName() {
        return this._originalForeignSpiesObject?.toCity;
    }

    getTroopCount() {
        return this._originalTroopsObject?.numOfTroops[0];
    }

    decreaseTroopCount(number) {
        this._originalTroopsObject.numOfTroops[0] -= number;
    }

    increaseTroopCount(number) {
        this._originalTroopsObject.numOfTroops[0] += number;
    }

    getNumberToSend(command_type) {
        return this._toSend[command_type];
    }

    setNumberToSend(command_type, number) {
        this._toSend[command_type] = number;
    }

    getOriginType() {
        if (this.isOwned()) {
            return 'Owned';
        } else {
            return 'Controlled';
        }
    }

    isOwned() {
        return this._originalTroopsObject?.owned;
    }
}
