<template>
    <div class="wrapper">
        <div class="troop-image-wrapper" :class="[size]">
            <img
                :src="require(`@/assets/icons/troops/${paramCase(troopName)}-tall.avif`)"
                alt="truck"
                class="troop-image"
                @click="openTroopDetailsModal(troopName)"
            />
        </div>
        <span v-if="isNameVisible" class="troop-name clickable" @click="openTroopDetailsModal(troopName)">
            {{ troopName }}
        </span>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { paramCase } from 'change-case';

export default {
    name: 'TroopImage',
    props: {
        troopName: {
            type: String,
            default: '',
        },
        isNameVisible: {
            type: Boolean,
            default: true,
        },
		size: {
			type: String, // 'small' | 'medium' | 'large'
			default: 'medium'
		}
    },
    methods: {
        ...mapMutations(['SET_CURRENT_TROOP']),
        paramCase,

        openTroopDetailsModal(troop_name) {
            if (troop_name) {
                this.$store.commit('SET_CURRENT_TROOP', troop_name);
            } else {
                return;
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    gap: 0.5rem;
	align-items: center;
}

.troop-image-wrapper.small {
	min-width: 1.75rem;
	height: 1.75rem;
	overflow: hidden;
}

.troop-image-wrapper.small .troop-image {
	width: 1.75rem;
	cursor: pointer;
}

.troop-image-wrapper.medium {
    min-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image-wrapper.medium .troop-image {
    width: 2.5rem;
    cursor: pointer;
}

.troop-image-wrapper.large {
	min-width: 3rem;
	height: 3rem;
	overflow: hidden;
}

.troop-image-wrapper.large .troop-image {
	width: 3rem;
	cursor: pointer;
}

.troop-name {
	max-width: 5rem;
}
</style>
