<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog" data-bs-focus="false">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cartofi Prajiti</h4>
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="action-info-wrapper">
                        <div class="left-wrapper">
                            <h5 v-if="viewedAction.fromUser" class="table-header">Origin:</h5>
                            <div v-if="viewedAction.fromUser" class="action-info-table">
                                <div class="row">
                                    <div class="col label">Player</div>
                                    <div class="col">
                                        <span class="clickable" @click="openProfileModal">{{
                                            getUsernameFromUserId(viewedAction.fromUser?.username)
                                        }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col label">City</div>
                                    <div class="col">
										<!-- TO DO: get coords directly from fromCity object -->
                                        <span class="clickable" @click="openCityModal"
                                            >{{ getCityNameFromId(viewedAction.fromCity?._id) }}
                                            {{ getCityCoordsFromId(viewedAction.fromCity?._id) }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <h5 v-if="viewedAction.toUser" class="table-header">Target:</h5>
                            <div v-if="viewedAction.toUser" class="action-info-table">
                                <div class="row">
                                    <div class="col label">Player</div>
                                    <div class="col">
                                        <span class="clickable" @click="openProfileModal">{{
											viewedAction.toUser?.username
                                        }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col label">City</div>
                                    <div class="col">
										<!-- TO DO: take coords directly from toCity object -->
                                        <span class="clickable" @click="openCityModal"
                                            >{{ viewedAction.toCity?.name }}
                                            {{ getCityCoordsFromId(viewedAction.toCity?._id) }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <h5 class="table-header">Arrival:</h5>
                            <div class="action-info-table">
                                <div class="row">
                                    <div class="col label">Duration</div>
                                    <div class="col">{{ viewedAction.staticDuration }}</div>
                                </div>
                                <div class="row">
                                    <div class="col label">Remaining</div>
                                    <div class="col">{{ viewedAction.duration }}</div>
                                </div>
                                <div class="row">
                                    <div class="col label">Arrival</div>
                                    <div class="col">{{ viewedAction.arrivalDateToShow }}</div>
                                </div>
                                <div class="row">
                                    <div class="col label">Estimated Return</div>
                                    <div class="col">{{ viewedAction.estimatedReturn }}</div>
                                </div>
                            </div>
                            <h5 v-if="viewedAction.resourcesStolen" class="table-header d-flex d-sm-none">Resources Captured:</h5>
                            <div v-if="viewedAction.resourcesStolen" class="action-info-table d-flex d-sm-none">
                                <div class="row">
                                    <div class="col label">Resources Captured:</div>
                                    <div class="col">
                                        <div class="resource-group-wrapper">
                                            <div class="resource-group">
                                                <img src="../assets/icons/aluminum_icon.svg" class="resource-icon" />
                                                <span>{{
                                                    viewedAction.resourcesStolen
                                                        .find(resource => resource.name === 'Aluminum')
                                                        .quantity.toLocaleString('ro')
                                                }}</span>
                                            </div>
                                            <div class="resource-group">
                                                <img src="../assets/icons/oil_icon.svg" class="resource-icon" />
                                                <span>{{
                                                    viewedAction.resourcesStolen
                                                        .find(resource => resource.name === 'Oil')
                                                        .quantity.toLocaleString('ro')
                                                }}</span>
                                            </div>
                                            <div class="resource-group">
                                                <img src="../assets/icons/metal_icon.svg" class="resource-icon" />
                                                <span>{{
                                                    viewedAction.resourcesStolen
                                                        .find(resource => resource.name === 'Metal')
                                                        .quantity.toLocaleString('ro')
                                                }}</span>
                                            </div>
                                            <div class="resource-group">
                                                <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
                                                <span>{{
                                                    viewedAction.resourcesStolen
                                                        .find(resource => resource.name === 'Rations')
                                                        .quantity.toLocaleString('ro')
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="viewedAction.resourcesStolen" class="right-wrapper">
                            <h5 class="table-header">Resources Captured:</h5>
                            <div class="action-info-table">
                                <div class="row">
                                    <div class="col label">Aluminium</div>
                                    <div class="col">
                                        <div class="resource-group">
                                            <img src="../assets/icons/aluminum_icon.svg" class="resource-icon" />
                                            <span>{{
                                                viewedAction.resourcesStolen
                                                    .find(resource => resource.name === 'Aluminum')
                                                    .quantity.toLocaleString('ro')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col label">Oil</div>
                                    <div class="col">
                                        <div class="resource-group">
                                            <img src="../assets/icons/oil_icon.svg" class="resource-icon" />
                                            <span>{{
                                                viewedAction.resourcesStolen
                                                    .find(resource => resource.name === 'Oil')
                                                    .quantity.toLocaleString('ro')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col label">Metal</div>
                                    <div class="col">
                                        <div class="resource-group">
                                            <img src="../assets/icons/metal_icon.svg" class="resource-icon" />
                                            <span>{{
                                                viewedAction.resourcesStolen
                                                    .find(resource => resource.name === 'Metal')
                                                    .quantity.toLocaleString('ro')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col label">Rations</div>
                                    <div class="col">
                                        <div class="resource-group">
                                            <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
                                            <span>{{
                                                viewedAction.resourcesStolen
                                                    .find(resource => resource.name === 'Rations')
                                                    .quantity.toLocaleString('ro')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 class="table-header">Troops:</h5>
                    <!-- filter out tables with no troops -->
                    <div
                        v-for="(troop_type, troop_type_index) in Object.keys(
                            Object.fromEntries(
                                Object.entries(troopsToBeShown).filter(([troopType, trps]) => {
                                    return Object.values(trps).some(troop => troop.owned !== 0);
                                }),
                            ),
                        )"
                        :key="troop_type_index"
                        class="troops-table desktop"
                    >
                        <div class="row">
                            <div class="col label">Origin</div>
                            <div
                                v-for="(troop, troop_index) in Object.keys(troopsToBeShown[troop_type])"
                                :key="troop_index"
                                class="col label"
                            >
                                <div
                                    class="troop-image-wrapper"
                                    @click="openTroopDetailsModal(troops[troop_type].find(trp => trp.name === troop))"
                                >
                                    <img
                                        :src="require(`../assets/icons/troops/${toKebabCase(troop)}-tall.avif`)"
                                        class="troop-image"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-for="(origin, origin_index) in originsPerTroopType[troop_type]" :key="origin_index" class="row">
                            <div v-if="origin === 'owned'" class="col">Owned</div>
                            <div v-else class="col">
                                <div class="city-icon-wrapper">
                                    <!-- TO DO: add dynamic city image -->
                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                </div>
                                <span class="city-name-and-coords clickable">
                                    {{ getCityNameFromId(origin) }}
                                    <span class="city-coords"> {{ getCityCoordsFromId(origin) }} </span>
                                </span>
                            </div>
                            <div
                                v-for="(troop, troop_index) in Object.keys(troopsToBeShown[troop_type])"
                                :key="troop_index"
                                class="col"
                            >
                                <span v-if="troopsToBeShown[troop_type][troop][origin] > 0">{{
                                    troopsToBeShown[troop_type][troop][origin]
                                }}</span>
                                <span v-else class="half-opacity">0</span>
                            </div>
                        </div>
                    </div>
                    <div v-for="(troop_type, troop_type_index) in Object.keys(troopsToBeShown)" :key="troop_type_index" class="">
                        <div
                            v-for="(troop, troop_index) in Object.keys(troopsToBeShown[troop_type]).filter(trps =>
                                Object.values(troopsToBeShown[troop_type][trps]).find(value => value > 0),
                            )"
                            :key="troop_index"
                            class="troops-table mobile"
                        >
                            <div class="row">
                                <div
                                    class="col label"
                                    @click="openTroopDetailsModal(troops[troop_type].find(trp => trp.name === troop))"
                                >
                                    <div class="troop-image-wrapper">
                                        <img
                                            :src="require(`../assets/icons/troops/${toKebabCase(troop)}-tall.avif`)"
                                            class="troop-image"
                                        />
                                    </div>
                                    {{ troop }}
                                </div>
                            </div>
                            <div
                                v-for="(origin, origin_index) in originsPerTroopType[troop_type].filter(
                                    org => troopsToBeShown[troop_type][troop][org] > 0,
                                )"
                                :key="origin_index"
                                class="row"
                            >
                                <div v-if="origin === 'owned'" class="col">Owned</div>
                                <div v-else class="col">
                                    <div class="city-icon-wrapper">
                                        <!-- TO DO: add dynamic city image -->
                                        <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                    </div>
                                    <span class="city-name-and-coords clickable">
                                        {{ getCityNameFromId(origin) }}
                                        <span class="city-coords"> {{ getCityCoordsFromId(origin) }} </span>
                                    </span>
                                </div>
                                <div class="col">
                                    <span>{{ troopsToBeShown[troop_type][troop][origin] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { paramCase } from 'change-case';

export default {
    name: 'MapActionModal',
    components: {},
    data() {
        return {
            troopsToBeShown: {},
        };
    },
    props: {
        troops: {
            type: Object,
            default: () => {},
        },
        viewedAction: {
            type: Object,
            default: () => {},
        },
        allCities: {
            type: Array,
            default: () => [],
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
    },
    computed: {
        originsPerTroopType() {
            const ORIGINS_OBJECT = {};
            Object.keys(this.troopsToBeShown).forEach(troop_type => {
                ORIGINS_OBJECT[troop_type] = [];
                Object.keys(this.troopsToBeShown[troop_type]).forEach(troop => {
                    const TROOP_ORIGINS = Object.keys(this.troopsToBeShown[troop_type][troop]);
                    TROOP_ORIGINS.forEach(foreign_origin => {
                        if (!ORIGINS_OBJECT[troop_type].includes(foreign_origin)) {
                            ORIGINS_OBJECT[troop_type].push(foreign_origin);
                        }
                    });
                });
            });
            return ORIGINS_OBJECT;
        },
    },
    watch: {
        'viewedAction.duration': {
            handler(newVal) {
                if (newVal === '0d 0h 0m 0s ') {
                    setTimeout(() => {
                        this.closeModal();
                    }, 1000);
                }
            },
        },
        viewedAction: {
            handler() {
                this.troopsToBeShown = this.buildTroopsToBeShown();
            },
        },
    },
    // created() {
    // 	setTimeout(() => {
    // 		this.troopsToBeShown = this.buildTroopsToBeShown()
    // 	}, 1000)
    // },
    methods: {
        buildTroopsToBeShown() {
            const IS_SPY_MISSION = !!this.viewedAction.troops;
            const TROOPS = {};
            if (IS_SPY_MISSION) {
                Object.keys(this.troops).forEach(key => {
                    TROOPS[key] = {};
                    this.troops[key].forEach(troop => {
                        if (troop.name === 'Spy') {
                            TROOPS[key][troop.name] = {
                                owned: this.viewedAction.troops.find(spies => spies.owned)?.numOfTroops,
                            };
                            this.viewedAction.troops.forEach(origin => {
                                if (!origin.owned && !TROOPS[key][troop.name][origin.lastCity]) {
                                    TROOPS[key][troop.name][origin.lastCity] = origin.numOfTroops;
                                } else if (!origin.owned && TROOPS[key][troop.name][origin.lastCity]) {
                                    TROOPS[key][troop.name][origin.lastCity] += origin.numOfTroops;
                                }
                            });
                        } else {
                            TROOPS[key][troop.name] = {
                                owned: 0,
                            };
                        }
                    });
                });
                return TROOPS;
            }
            Object.keys(this.troops).forEach(key => {
                TROOPS[key] = {};
                this.troops[key].forEach(troop => {
                    TROOPS[key][troop.name] = {
                        owned: 0,
                    };
                    this.viewedAction.troops.forEach(origin => {
                        const TROOP_FOUND_INDEX = origin.troopsName.findIndex(trp => trp === troop.name);
                        if (TROOP_FOUND_INDEX !== -1) {
                            if (!origin.owned && !TROOPS[key][troop.name][origin.lastCity]) {
                                TROOPS[key][troop.name][origin.lastCity] = origin.numOfTroops[TROOP_FOUND_INDEX];
                            } else if (!origin.owned && TROOPS[key][troop.name][origin.lastCity]) {
                                TROOPS[key][troop.name][origin.lastCity] += origin.numOfTroops[TROOP_FOUND_INDEX];
                            } else if (origin.owned) {
                                TROOPS[key][troop.name].owned += origin.numOfTroops[TROOP_FOUND_INDEX];
                            }
                        }
                    });
                });
            });
            return TROOPS;
        },

        // to be used temporarily
        getCityNameFromId(city_id) {
            if (!city_id) {
                return '';
            }
            return this.allCities.length ? this.allCities.find(city => city._id === city_id)?.name : '';
        },
        getCityCoordsFromId(city_id) {
            if (!city_id) {
                return '';
            }
            const CITY = this.allCities.find(city => city._id === city_id);
            return `( ${CITY.coords.x} | ${CITY.coords.y} )`;
        },
        // need better way to get player name
        getUsernameFromUserId(user_id) {
            // return this.allCities.length ? this.allCities.find(city => city.user._id === user_id)?.user.username : '';
            return user_id;
        },
        closeModal() {
            document.getElementById('map-action-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('map-action-modal').style.transform = 'translateY(-20px)';
            document.getElementById('map-action-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('map-action-modal').style.display = 'none';
                document.getElementById('map-action-modal').classList.remove('show');
                document.getElementById('map-action-modal').style.removeProperty('transform');
                document.getElementById('map-action-modal').style.removeProperty('opacity');
                document.body.classList.remove('modal-open');
            }, 150);
        },
        openCityModal() {
            document.getElementById('city-modal').style.transform = 'translateY(-20px)';
            document.getElementById('city-modal').style.opacity = '0';
            document.getElementById('city-modal').style.display = 'block';
            document.getElementById('city-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('city-modal').style.transition = 'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('city-modal').style.transform = 'translateY(0)';
                document.getElementById('city-modal').style.opacity = '1';
            }, 0);
        },
        openProfileModal() {
            document.getElementById('profile-modal').style.transform = 'translateY(-20px)';
            document.getElementById('profile-modal').style.opacity = '0';
            document.getElementById('profile-modal').style.display = 'block';
            document.getElementById('profile-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('profile-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('profile-modal').style.transform = 'translateY(0)';
                document.getElementById('profile-modal').style.opacity = '1';
            }, 0);
        },
        openTroopDetailsModal(troop) {
            if (troop) {
                this.setCurrentTroop(troop);
            } else {
                return;
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },
        toKebabCase(string) {
            return paramCase(string);
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
    padding: 0;
}

.modal-title {
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    /*min-width: 4.5rem;*/
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.clickable {
    color: burlywood;
    cursor: pointer;
}

.clickable:hover {
    color: cornflowerblue;
}

.action-info-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 4%;
}

.action-info-wrapper .left-wrapper {
    width: 20rem;
}

.action-info-wrapper .right-wrapper {
    width: 50%;
}

.table-header {
    margin: 1rem auto 0.5rem auto;
    color: lightgoldenrodyellow;
}

.action-info-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}

.action-info-table .col {
    width: calc(100% - 6rem);
    flex: unset;
}

.action-info-table .col.label {
    width: 6rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
}

.troops-table.mobile {
    display: none;
}

.troops-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 1rem;
}

.troops-table .row {
    min-height: 2.5rem;
}

.troops-table .col {
    min-width: unset;
    flex: unset;
    padding: 0.25rem;
    justify-content: center;
}

.troops-table .row .col:first-child {
    min-width: 8rem;
}

.troop-image-wrapper {
    min-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    cursor: pointer;
}

.city-icon-wrapper {
    width: fit-content;
}

.city-icon {
    height: 1.5rem;
    cursor: pointer;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.125rem;
    flex-wrap: wrap;
    font-size: 0.75rem;
}

.city-coords {
    text-wrap: none;
}

.resource-group-wrapper {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
}

.resource-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: auto;
}

.resource-icon {
    height: 1rem;
}

.half-opacity {
    opacity: 0.5;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 80%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 700px) {
    .modal-dialog {
        max-width: 96%;
        margin: 0 auto;
    }
    .col {
        font-size: 0.75rem;
        /*min-width: 3rem;*/
        /*padding: 0.25rem;*/
        gap: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
    /*.btn {*/
    /*    font-size: 0.75rem;*/
    /*    padding: 0.25rem 0.75rem;*/
    /*}*/
}

@media screen and (max-width: 576px) {
    .action-info-wrapper .left-wrapper {
        width: 100%;
    }
    .action-info-wrapper .right-wrapper {
        display: none;
    }
}

@media screen and (max-width: 520px) {
    .troops-table .col.label:not(:first-child) {
        padding: 0;
    }
}

@media screen and (max-width: 480px) {
    .troops-table.desktop {
        display: none;
    }
    .troops-table.mobile {
        display: flex;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>