import state from '@/store/modules/authentication/state';
import mutations from '@/store/modules/authentication/mutations';
import actions from '@/store/modules/authentication/actions';
import getters from '@/store/modules/authentication/getters';

export default {
    state,
    mutations,
    actions,
    getters,
};
