<template>
    <div class="expandable-wrapper" :class="{ expanded: isExpanded }">
        <slot />
    </div>
    <span class="expand-label clickable" @click="toggleExpand">
        {{ isExpanded ? expandedLabel : collapsedLabel }}
        <span class="expand-arrow" :class="{ inverted: isExpanded }">&#10095;</span>
    </span>
</template>

<script>
export default {
    name: 'ExpandableSection',
    props: {
        contentRef: {
            type: HTMLElement,
        },
        collapsedLabel: {
            type: String,
            default: 'See more',
        },
        expandedLabel: {
            type: String,
            default: 'See less',
        },
    },
    data() {
        return {
            isExpanded: false,
            contentHeight: 0,
            overflow: 'hidden',
        };
    },
    methods: {
        toggleExpand() {
            this.contentHeight = this.contentRef?.offsetHeight;
            this.isExpanded = !this.isExpanded;
            if (this.isExpanded) {
                setTimeout(() => {
                    this.overflow = 'visible';
                }, 250);
            } else {
                this.overflow = 'hidden';
            }
        },
    },
};
</script>

<style scoped>
.expandable-wrapper {
    height: 0;
    transform-origin: top;
    overflow: hidden;
    transition: height 0.25s ease-out;
}

.expandable-wrapper.expanded {
    height: v-bind(contentHeight + 'px');
    overflow: v-bind(overflow);
}

.expand-label {
    text-align: center;
}

.expand-arrow {
	writing-mode: vertical-lr;
	transition: transform 0.25s;
}

.expand-arrow.inverted {
	transform: rotateZ(180deg);
}
</style>
