import { ArmoredTroops, InfantryTroops, Planes, TroopTypes } from "@/utils/constants/troops";

export function buildControlledReinforcementsTemplate({ reinforcements, troop_name, current_city_id }) {
    // TO DO: filter out reinforcements with power less than 100
    let index = 0;
    const filteredReinforcementsArray = [];
    for (let i = 0; i < reinforcements.length; i++) {
        const reinforcementObj = reinforcements[i];
        for (let j = 0; j < reinforcementObj.troopsInCities.length; j++) {
            const troopsInCitiesObj = reinforcementObj.troopsInCities[j];

            const isValid =
                troopsInCitiesObj.currentCity._id === current_city_id &&
                troopsInCitiesObj.mission === 'Reinforcing' &&
                troopsInCitiesObj.arrived === true;

            if (isValid) {
                if (troop_name && troopsInCitiesObj.troopName !== troop_name) {
                    continue;
                }
                const REINFORCEMENT = {
                    id: reinforcementObj._id,
                    troopName: troopsInCitiesObj.troopName,
                    numOfTroops: troopsInCitiesObj.numOfTroops,
                    toSend: 0,
                    fromCity: reinforcementObj.fromCity,
                    fromUser: reinforcementObj.fromUser,
                    toCity: reinforcementObj.toCity,
                    toUser: reinforcementObj.toUser,
                    index: index++,
                };
                filteredReinforcementsArray.push(REINFORCEMENT);
            }
        }
    }
    return filteredReinforcementsArray;
}

export function getTroopType(troop_name) {
    if (InfantryTroops.includes(troop_name)) {
        return TroopTypes.INFANTRY;
    } else if (ArmoredTroops.includes(troop_name)) {
        return TroopTypes.ARMORED;
    } else if (Planes.includes(troop_name)) {
        return TroopTypes.PLANE;
    } else {
        return TroopTypes.SPECIAL;
    }
}

