<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Airport</h4>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="planes-capacity-wrapper">
                        <div class="row">
                            <div class="col label">Current Capacity: 297 / 300</div>
                        </div>
                        <div class="row">
                            <div class="col label">Capacity at Level 5: 410</div>
                        </div>
                    </div>
                    <div class="t-wrapper planes-table">
                        <div class="t-row t-header">
                            <div class="t-col">Unit</div>
                            <div class="t-col">Owned</div>
                            <div class="t-col">Controlled</div>
                            <div class="t-col">In Mission</div>
                        </div>
                        <div v-for="plane in newPlanes" :key="plane.getName()" class="t-row">
                            <TroopSelectRow
                                :troop="plane"
                                :command-type="CommandTypesForTroopSelection.PATROL_OR_INTERCEPT"
                                :reinforcements-category="ReinforcementCategory.RECEIVED"
                                :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                :reinforcements-filter-value="ReinforcementControlLevel.PARTIAL_OR_FULL"
								:is-troop-name-visible="true"
                            />
							<div class="t-col">{{ calculatePlanesInMission(plane.getName()) }}</div>
                        </div>
                    </div>
                    <div class="planes-table">
                        <div class="row">
                            <div class="col label large">Unit</div>
                            <div class="col label small">Owned</div>
                            <div class="col label small">Controlled</div>
                            <div class="col label small">In Mission</div>
                        </div>
                        <div v-for="(plane, plane_index) in planes" :key="plane_index" class="row">
                            <div class="col large">
                                <div class="troop-image-wrapper">
                                    <img
                                        :src="require(`../assets/icons/troops/${toKebabCase(plane.name)}-tall.avif`)"
                                        alt=""
                                        class="troop-image"
                                        @click="openTroopDetailsModal(plane)"
                                    />
                                </div>
                                <span class="troop-name" @click="openTroopDetailsModal(plane)">{{ plane.name }}</span>
                            </div>
                            <div class="col small">
                                <input
                                    v-model="
                                        numberOfOwnedPlanesToSend[
                                            numberOfOwnedPlanesToSend.find(pln => pln.name === plane.name).index
                                        ]['number']
                                    "
                                    :disabled="!plane.numOfTroops"
                                    min="0"
                                    :max="plane.numOfTroops"
                                    class="form-control"
                                />
                                <span
                                    class="max-troops-to-send"
                                    @click="
                                        assignNumberOfOwnedTroopsToSend(
                                            numberOfOwnedPlanesToSend.find(pln => pln.name === plane.name).index,
                                            'number',
                                            plane.numOfTroops,
                                        )
                                    "
                                    >({{ plane.numOfTroops.toLocaleString('ro') }})</span
                                >
                            </div>
                            <div class="col small controlled-reinforcements-select-column">
                                <input
                                    v-if="getControlledReinforcementsPerTroop(plane.name).length === 0"
                                    value="0"
                                    class="form-control"
                                    disabled
                                />
                                <input
                                    v-else-if="getControlledReinforcementsPerTroop(plane.name).length === 1"
                                    v-model="getControlledReinforcementsPerTroop(plane.name)[0].toSend"
                                    class="form-control"
                                />
                                <input
                                    v-else
                                    :value="calculateTroopsSelectedFromDropdown(getControlledReinforcementsPerTroop(plane.name))"
                                    class="form-control"
                                    disabled
                                />
                                <span class="max-troops-to-send" @click="assignAllControlledReinforcements(plane.name)"
                                    >({{ plane.controlledReinforcements.toLocaleString('ro') }})</span
                                >
                                <button
                                    v-if="getControlledReinforcementsPerTroop(plane.name)?.length > 1"
                                    class="btn neutral-button reinforcements-dropdown-button"
                                    @click="toggleExtraReinforcementOptionsVisibility(plane_index)"
                                >
                                    <span>&#10095;</span>
                                </button>
                                <Transition name="tooltip-transition">
                                    <div
                                        v-if="extraReinforcementOptionsVisible[plane_index]"
                                        class="reinforcements-dropdown-wrapper"
                                    >
                                        <div class="row">
                                            <div class="col label">From</div>
                                            <div class="col label">City</div>
                                            <div class="col label">Troops</div>
                                        </div>
                                        <div
                                            v-for="(reinforcement, reinforcement_index) in getControlledReinforcementsPerTroop(
                                                plane.name,
                                            )"
                                            :key="reinforcement_index"
                                            class="row"
                                        >
                                            <div class="col">
                                                {{ reinforcement.fromUser?.username }}
                                            </div>
                                            <!-- TO DO: add city image before name -->
                                            <div class="col">
                                                {{ reinforcement.fromCity?.name }}
                                            </div>
                                            <div class="col">
                                                <div class="troop-image-wrapper">
                                                    <img
                                                        :src="
                                                            require(`../assets/icons/troops/${toKebabCase(
                                                                reinforcement.troopName,
                                                            )}-tall.avif`)
                                                        "
                                                        alt=""
                                                        class="troop-image"
                                                        @click="openTroopDetailsModal(plane)"
                                                    />
                                                </div>
                                                <input
                                                    v-model="reinforcement.toSend"
                                                    min="0"
                                                    :max="reinforcement.numOfTroops"
                                                    class="form-control"
                                                />
                                                <span
                                                    class="max-troops-to-send"
                                                    @click="
                                                        assignNumberOfControlledReinforcementsToSend(
                                                            reinforcement.index,
                                                            reinforcement.numOfTroops,
                                                        )
                                                    "
                                                    >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="col small">{{ calculatePlanesInMission(plane.name) }}</div>
                        </div>
                    </div>
                    <h4 class="table-header">Missions:</h4>
                    <div class="planes-table">
                        <div class="row">
                            <div class="col label">Mission Name</div>
                            <div class="col label">Target</div>
                            <div class="col label">Oil Needed</div>
                            <div class="col label button-column">Action</div>
                        </div>
                        <div class="row">
                            <div class="col mission-name">Patrol</div>
                            <div class="col">
                                <div class="city-icon-wrapper">
                                    <!-- TO DO: add dynamic city image -->
                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                </div>
                                <span class="city-name-and-coords">
                                    {{ currentCityName }}
                                    <span class="city-coords"> ( {{ currentCityCoords?.x }} | {{ currentCityCoords?.y }} ) </span>
                                </span>
                            </div>
                            <div class="col">{{ oilNeeded.toLocaleString('ro') }}</div>
                            <div class="col button-column">
                                <button class="btn blue-button" :disabled="awaitingResponse" @click="validateAndSendPatrol">
                                    Patrol
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col mission-name">Intercept attack</div>
                            <div class="col attacks-dropdown-column">
                                <span v-if="!incomingAttacks.length">No incoming attacks</span>
                                <div v-else class="attacks-dropdown-wrapper">
                                    <div class="selected-attack">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image // stop using getCityNameFromId function -->
                                            <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="command-target-wrapper">
                                            Attack from
                                            <span class="command-target">{{ selectedAttack.fromCity?.name }}</span>
                                        </span>
                                    </div>
                                    <button
                                        class="btn neutral-button reinforcements-dropdown-button"
                                        @click="toggleAttacksDropdownVisibility"
                                    >
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                                <Transition name="tooltip-transition">
                                    <div v-if="attacksDropdownVisible" class="attacks-dropdown">
                                        <div class="row">
                                            <div class="col label">Attack from</div>
                                            <div class="col label">Duration</div>
                                            <div class="col label"></div>
                                        </div>
                                        <div v-for="(attack, attack_index) in incomingAttacks" :key="attack_index" class="row">
                                            <div class="col">
                                                <div class="city-icon-wrapper">
                                                    <!-- TO DO: add dynamic city image // stop using getCityNameFromId function -->
                                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                                </div>
                                                <span class="command-target-wrapper">
                                                    Attack from
                                                    <span class="command-target">{{ attack.fromCity?.name }}</span>
                                                </span>
                                            </div>
                                            <div class="col">{{ attack.duration }}</div>
                                            <div class="col">
                                                <button class="btn yellow-button" @click="selectAttack(attack)">Select</button>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="col">{{ oilNeeded.toLocaleString('ro') }}</div>
                            <div class="col button-column">
                                <button
                                    class="btn blue-button"
                                    :disabled="awaitingResponse"
                                    @click="validateAndSendInterceptingAttack"
                                >
                                    Intercept
                                </button>
                            </div>
                        </div>
                    </div>
                    <h4 class="table-header">Active Missions:</h4>
                    <div class="planes-table active-missions-table">
                        <div class="row">
                            <div class="col label">Mission</div>
                            <div class="col label">Planes</div>
                            <div class="col label">Duration</div>
                            <div class="col label button-column">Action</div>
                        </div>
                        <div v-for="(mission, mission_index) in missions" :key="mission.originalData._id" class="row">
                            <div class="col">
                                <div class="city-icon-wrapper">
                                    <!-- TO DO: add dynamic city image -->
                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                </div>
                                <span class="city-name-and-coords">
                                    {{ mission.actionDescription }} {{ mission.target.name }}
                                </span>
                            </div>
                            <div class="col mission-planes-dropdown-column">
                                <div class="mission-planes-dropdown-wrapper">
                                    <div class="total-planes">{{ calculateAllPlanes(mission.originalData) }}</div>
                                    <button
                                        class="btn neutral-button reinforcements-dropdown-button"
                                        @click="togglePatrolPlanesDropdownVisibility(mission_index)"
                                    >
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                                <Transition name="tooltip-transition">
                                    <div v-if="patrolPlanesDropdownVisible[mission_index]" class="planes-dropdown">
                                        <div
                                            v-for="(plane, plane_index) in calculatePlanesFromMission(mission.originalData)"
                                            :key="plane_index"
                                            class="row"
                                        >
                                            <div class="col">
                                                <div class="troop-image-wrapper">
                                                    <img
                                                        :src="
                                                            require(`../assets/icons/troops/${toKebabCase(
                                                                plane.troopName,
                                                            )}-tall.avif`)
                                                        "
                                                        alt=""
                                                        class="troop-image"
                                                        @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"
                                                    />
                                                </div>
                                                <span
                                                    class="troop-name"
                                                    @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"
                                                    >{{ plane.troopName }}</span
                                                >
                                            </div>
                                            <div class="col">{{ plane.numOfTroops }}</div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="col">{{ mission.progressTracking.dynamicDurationString }}</div>
                            <div class="col button-column">
                                <button class="btn red-button" :disabled="awaitingResponse">Cancel</button>
                            </div>
                        </div>
<!--                        <div-->
<!--                            v-for="(intercept_mission, intercept_mission_index) in intercepts"-->
<!--                            :key="intercept_mission_index"-->
<!--                            class="row"-->
<!--                        >-->
<!--                            <div class="col">Intercepting attack</div>-->
<!--                            <div class="col">-->
<!--                                {{ intercept_mission.interceptedAttack }}-->
<!--                            </div>-->
<!--                            <div class="col mission-planes-dropdown-column">-->
<!--                                <div class="mission-planes-dropdown-wrapper">-->
<!--                                    <div class="total-planes">{{ calculateAllPlanes(intercept_mission) }}</div>-->
<!--                                    <button-->
<!--                                        class="btn neutral-button reinforcements-dropdown-button"-->
<!--                                        @click="toggleInterceptPlanesDropdownVisibility(intercept_mission_index)"-->
<!--                                    >-->
<!--                                        <span>&#10095;</span>-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                                <Transition name="tooltip-transition">-->
<!--                                    <div v-if="interceptPlanesDropdownVisible[intercept_mission_index]" class="planes-dropdown">-->
<!--                                        <div-->
<!--                                            v-for="(plane, plane_index) in calculatePlanesFromMission(intercept_mission)"-->
<!--                                            :key="plane_index"-->
<!--                                            class="row"-->
<!--                                        >-->
<!--                                            <div class="col">-->
<!--                                                <div class="troop-image-wrapper">-->
<!--                                                    <img-->
<!--                                                        :src="-->
<!--                                                            require(`../assets/icons/troops/${toKebabCase(plane.troopName)}-tall.avif`)-->
<!--                                                        "-->
<!--                                                        alt=""-->
<!--                                                        class="troop-image"-->
<!--                                                        @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"-->
<!--                                                    />-->
<!--                                                </div>-->
<!--                                                <span-->
<!--                                                    class="troop-name"-->
<!--                                                    @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"-->
<!--                                                    >{{ plane.troopName }}</span-->
<!--                                                >-->
<!--                                            </div>-->
<!--                                            <div class="col">{{ plane.numOfTroops }}</div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </Transition>-->
<!--                            </div>-->
<!--                            <div class="col">{{ intercept_mission.duration }}</div>-->
<!--                            <div class="col button-column">-->
<!--                                <button class="btn red-button" :disabled="awaitingResponse">Cancel</button>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { paramCase } from 'change-case';
import { ActionTypeIdentifier } from '@/utils/constants/actions';
import {
    TroopTypes,
    CommandTypesForTroopSelection,
    ReinforcementFilterType,
    ReinforcementControlLevel,
} from '@/utils/constants/troops';
import TroopSelectRow from '@/components/generic/TroopSelect/TroopSelectRow';
import { ReinforcementCategory } from '@/utils/constants/actions';

export default {
    name: 'AirportModal',
    components: { TroopSelectRow },
    props: {
        awaitingResponse: {
            type: Boolean,
            default: false,
        },
        cityId: {
            type: String,
            default: '',
        },
        currentCityName: {
            type: String,
            default: '',
        },
        currentCityTile: {
            type: Number,
            default: 0,
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
        allCities: {
            type: Array,
            default: () => [],
        },
        planes: {
            type: Array,
            default: () => [],
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
        reinforcements: {
            type: Array,
            default: () => [],
        },
        attacks: {
            type: Array,
            default: () => [],
        },
        patrols: {
            type: Array,
            default: () => [],
        },
        intercepts: {
            type: Array,
            default: () => [],
        },
        returningIntercepts: {
            type: Array,
            default: () => [],
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
        sendPatrol: {
            type: Function,
            default: () => null,
        },
        sendInterceptingAttack: {
            type: Function,
            default: () => null,
        },
    },
    data() {
        return {
            TroopTypes,
            CommandTypesForTroopSelection,
            ReinforcementFilterType,
            ReinforcementControlLevel,
            ReinforcementCategory,

            numberOfOwnedPlanesToSend: this.buildOwnedTroopsTemplate(), // creates template to be used by v-model
            numberOfControlledReinforcementsToSend: this.buildControlledReinforcementsTemplate(), // creates template to be used by v-model
            extraReinforcementOptionsVisible: new Array(this.planes.length).fill(false),
            attacksDropdownVisible: false,
            // TO DO: set watcher for patrolPlanesDropdownVisible and interceptPlanesDropdownVisible
            // to increase or decrease size
            patrolPlanesDropdownVisible: new Array(this.patrols.length).fill(false),
            selectedAttack: null,
            interceptPlanesDropdownVisible: new Array(this.intercepts.length).fill(false),
        };
    },
    computed: {
        formattedTroops() {
            return this.$store.getters.getCurrentCityFormattedTroops;
        },
        // TO DO: rename newPlanes to planes when refactor is done
        newPlanes() {
            return this.formattedTroops.filter(troop => troop.getType() === TroopTypes.PLANE);
        },
        incomingAttacks() {
            return this.attacks.filter(attack => attack.toCity?._id === this.cityId);
        },
        missions() {
            const RelevantActions = [
                ActionTypeIdentifier.PATROL,
                ActionTypeIdentifier.INCOMING_INTERCEPT,
                ActionTypeIdentifier.OUTGOING_INTERCEPT,
                ActionTypeIdentifier.RETURNING_INTERCEPT,
            ];
            const FilteredActions = new Set();

            this.mapActions.forEach(action => {
                if (RelevantActions.includes(action.actionType)) {
                    FilteredActions.add(action);
                }
            });
            return FilteredActions;
        },
        oilNeeded() {
            const OIL_FOR_OWNED = this.numberOfOwnedPlanesToSend.reduce((accumulator, currentValue) => {
                return (
                    accumulator + this.planes.find(plane => plane.name === currentValue.name).oilConsumption * currentValue.number
                );
            }, 0);
            const OIL_FOR_CONTROLLED = this.numberOfControlledReinforcementsToSend.reduce((accumulator, currentValue) => {
                return (
                    accumulator +
                    this.planes.find(plane => plane.name === currentValue.troopName).oilConsumption * currentValue.toSend
                );
            }, 0);
            return OIL_FOR_OWNED + OIL_FOR_CONTROLLED;
        },
    },
    created() {
        this.selectedAttack = this.incomingAttacks[0] || null;
    },
    methods: {
        toKebabCase(string) {
            return paramCase(string);
        },

        buildOwnedTroopsTemplate() {
            const planes = this.planes;
            const toSendPerTroop = [];
            let index = 0;
            for (let i = 0; i < planes.length; i++) {
                const troopObj = {
                    name: planes[i].name,
                    number: 0,
                    index: index++,
                };
                toSendPerTroop.push(troopObj);
            }
            return toSendPerTroop;
        },

        buildControlledReinforcementsTemplate() {
            // TO DO: filter out reinforcements with power less than 100
            let index = 0;
            const reinforcements = this.reinforcements;
            const filteredReinforcementsArray = [];
            for (let i = 0; i < reinforcements.length; i++) {
                const reinforcementObj = reinforcements[i];
                for (let j = 0; j < reinforcementObj.troopsInCities.length; j++) {
                    const troopsInCitiesObj = reinforcementObj.troopsInCities[j];

                    const isValid =
                        troopsInCitiesObj.currentCity._id === this.cityId &&
                        troopsInCitiesObj.mission === 'Reinforcing' &&
                        troopsInCitiesObj.arrived === true;

                    const namesArray = ['Spy Plane', 'Transport Plane', 'Fighter', 'Close Air Support', 'Bombing Plane'];
                    if (isValid) {
                        if (!namesArray.includes(troopsInCitiesObj.troopName)) {
                            continue;
                        }
                        const REINFORCEMENT = {
                            id: reinforcementObj._id,
                            troopName: troopsInCitiesObj.troopName,
                            numOfTroops: troopsInCitiesObj.numOfTroops,
                            toSend: 0,
                            fromCity: reinforcementObj.fromCity,
                            fromUser: reinforcementObj.fromUser,
                            toCity: reinforcementObj.toCity,
                            toUser: reinforcementObj.toUser,
                            index: index++,
                        };
                        // const OIL_PER_TROOP = this.planes.find(plane => plane.name === troopsInCitiesObj.troopName).oil
                        // REINFORCEMENT.oilNeeded = OIL_PER_TROOP * REINFORCEMENT.toSend;
                        filteredReinforcementsArray.push(REINFORCEMENT);
                    }
                }
            }
            return filteredReinforcementsArray;
        },

        assignNumberOfOwnedTroopsToSend(index, origin, number) {
            this.numberOfOwnedPlanesToSend[index][origin] = number;
        },

        assignAllControlledReinforcements(troop_name) {
            const reinforcements = this.getControlledReinforcementsPerTroop(troop_name);
            for (let i = 0; i < reinforcements.length; i++) {
                reinforcements[i].toSend = reinforcements[i].numOfTroops;
            }
        },

        assignNumberOfControlledReinforcementsToSend(index, number) {
            this.numberOfControlledReinforcementsToSend[index].toSend = number;
        },

        getControlledReinforcementsPerTroop(troop_name) {
            const reinforcements = this.numberOfControlledReinforcementsToSend;
            return reinforcements.filter(
                reinforcement => reinforcement.troopName === troop_name && reinforcement.fromCity?._id !== this.cityId,
            );
        },

        getOwnedTroopsToSend() {
            const troopsNotZero = this.numberOfOwnedPlanesToSend.filter(plane => plane.number > 0);
            const troopNamesArray = [];
            const numOfTroopsArray = [];

            for (let i = 0; i < troopsNotZero.length; i++) {
                troopNamesArray.push(troopsNotZero[i].name);
                numOfTroopsArray.push(parseInt(troopsNotZero[i].number));
            }

            if (troopsNotZero.length) {
                return {
                    troopsName: troopNamesArray,
                    numOfTroops: numOfTroopsArray,
                    owned: true,
                };
            }
            return null;
        },

        getControlledReinforcementsToSendGroupedById() {
            const reinforcements = this.numberOfControlledReinforcementsToSend;
            const reinforcementsGroupedById = {};
            const reinforcementsNotZero = reinforcements.filter(reinforcement => reinforcement.toSend > 0);

            for (let i = 0; i < reinforcementsNotZero.length; i++) {
                if (!reinforcementsGroupedById[reinforcementsNotZero[i].id]) {
                    reinforcementsGroupedById[reinforcementsNotZero[i].id] = {
                        troopsName: [reinforcementsNotZero[i].troopName],
                        numOfTroops: [reinforcementsNotZero[i].toSend],
                        owned: false,
                    };
                } else {
                    const IndexOfTroopAlreadyInArray = reinforcementsGroupedById[
                        reinforcementsNotZero[i].id
                    ].troopsName.findIndex(troop_name => troop_name === reinforcementsNotZero[i].troopName);
                    if (IndexOfTroopAlreadyInArray !== -1) {
                        reinforcementsGroupedById[reinforcementsNotZero[i].id].numOfTroops[IndexOfTroopAlreadyInArray] +=
                            reinforcementsNotZero[i].toSend;
                    } else {
                        reinforcementsGroupedById[reinforcementsNotZero[i].id].troopsName.push(
                            reinforcementsNotZero[i].troopName,
                        );
                        reinforcementsGroupedById[reinforcementsNotZero[i].id].numOfTroops.push(reinforcementsNotZero[i].toSend);
                    }
                }
            }

            return reinforcementsGroupedById;
        },

        getControlledReinforcementsToSend() {
            const reinforcementsGroupedById = this.getControlledReinforcementsToSendGroupedById();
            const reinforcementsToSendArray = [];
            const keys = Object.keys(reinforcementsGroupedById);
            for (let i = 0; i < keys.length; i++) {
                reinforcementsToSendArray.push(reinforcementsGroupedById[keys[i]]);
            }
            return reinforcementsToSendArray;
        },

        getControlledReinforcementsIdArray() {
            return Object.keys(this.getControlledReinforcementsToSendGroupedById());
        },

        getTroopsAndReinforcementsForCommand() {
            if (!this.getControlledReinforcementsToSend()) {
                return [this.getOwnedTroopsToSend()];
            }
            if (!this.getOwnedTroopsToSend()) {
                return [...this.getControlledReinforcementsToSend()];
            }
            return [this.getOwnedTroopsToSend(), ...this.getControlledReinforcementsToSend()];
        },

        calculateTroopsSelectedFromDropdown(troops) {
            return troops.reduce((accumulator, currentValue) => {
                if (!currentValue.toSend) {
                    return accumulator;
                }
                return accumulator + parseInt(currentValue.toSend, 10);
            }, 0);
        },

        toggleExtraReinforcementOptionsVisibility(index) {
            this.extraReinforcementOptionsVisible[index] = !this.extraReinforcementOptionsVisible[index];
        },

        toggleAttacksDropdownVisibility() {
            this.attacksDropdownVisible = !this.attacksDropdownVisible;
        },

        togglePatrolPlanesDropdownVisibility(index) {
            this.patrolPlanesDropdownVisible[index] = !this.patrolPlanesDropdownVisible[index];
        },

        toggleInterceptPlanesDropdownVisibility(index) {
            this.interceptPlanesDropdownVisible[index] = !this.interceptPlanesDropdownVisible[index];
        },

        selectAttack(attack) {
            if (this.selectedAttack && this.selectedAttack._id === attack._id) {
                this.toggleAttacksDropdownVisibility();
                return;
            }
            this.selectedAttack = attack;
            this.toggleAttacksDropdownVisibility();
        },

        calculatePlanesFromMission(mission) {
            const planes = mission.troops;
            const PLANES_ARRAY = [];
            for (let i = 0; i < planes.length; i++) {
                for (let j = 0; j < planes[i].troopsName.length; j++) {
                    if (!PLANES_ARRAY.find(pln => pln.troopName === planes[i].troopsName[j])) {
                        const PLANE_OBJECT = {
                            troopName: planes[i].troopsName[j],
                            numOfTroops: 0,
                        };
                        PLANES_ARRAY.push(PLANE_OBJECT);
                    }
                    PLANES_ARRAY.filter(pln => pln.troopName === planes[i].troopsName[j])[0].numOfTroops +=
                        planes[i].numOfTroops[j];
                }
            }
            return PLANES_ARRAY;
        },

        calculateAllPlanes(mission) {
            return this.calculatePlanesFromMission(mission).reduce((accumulator, currentValue) => {
                return accumulator + currentValue.numOfTroops;
            }, 0);
        },

        calculatePlanesInMission(plane_name) {
            let total = 0;
            const MISSIONS = {
                patrols: this.patrols,
                intercepts: this.intercepts,
            };
            for (const mission_type of Object.keys(MISSIONS)) {
                if (!MISSIONS[mission_type] || !MISSIONS[mission_type].length) {
                    continue;
                }
                for (let i = 0; i < MISSIONS[mission_type].length; i++) {
                    for (let j = 0; j < MISSIONS[mission_type][i].troops.length; j++) {
                        for (let k = 0; k < MISSIONS[mission_type][i].troops[j].troopsName.length; k++) {
                            if (MISSIONS[mission_type][i].troops[j].troopsName[k] === plane_name) {
                                total += MISSIONS[mission_type][i].troops[j].numOfTroops[k];
                            }
                        }
                    }
                }
            }
            return total;
        },

        getPlaneFromName(plane_name) {
            return this.planes.find(pln => pln.name === plane_name);
        },

        // to be used temporarily
        getCityNameFromId(city_id) {
            return this.allCities.length ? this.allCities.find(city => city._id === city_id)?.name : '';
        },

        openTroopDetailsModal(troop) {
            if (troop) {
                this.setCurrentTroop(troop);
            } else {
                return;
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },

        validateAndSendPatrol() {
            // TO DO: add validations
            this.sendPatrol({
                range: 50,
                troops: this.getTroopsAndReinforcementsForCommand(),
                reinforcements: this.getControlledReinforcementsIdArray(),
                attackOnSight: true,
                duration: 5,
            });
        },

        validateAndSendInterceptingAttack() {
            // TO DO: add validations
            this.sendInterceptingAttack({
                troops: this.getTroopsAndReinforcementsForCommand(),
                reinforcements: this.getControlledReinforcementsIdArray(),
                attackId: this.selectedAttack._id,
            });
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.small {
    flex-basis: 25%;
}

.large {
    flex-basis: 50%;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-control:disabled {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
    opacity: 0.25;
}

.max-troops-to-send {
    color: wheat;
    font-size: 0.75rem;
}

.max-troops-to-send:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.controlled-reinforcements-select-column {
    position: relative;
}

.reinforcements-dropdown-button > span {
    writing-mode: vertical-lr;
}

.btn {
    padding: 0.25rem;
}

.neutral-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(30, 30, 30), rgba(255, 255, 255, 0.2));
    border: 3px solid dimgray;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.neutral-button:hover {
    background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2), rgb(60, 60, 60));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}

.reinforcements-dropdown-wrapper {
    position: absolute;
    top: 3.5rem;
    right: -1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: black;
    padding: 2px;
    width: max-content;
}

.reinforcements-dropdown-wrapper .col:not(.label) {
    background: rgba(150, 150, 150, 0.125);
}

.reinforcements-dropdown-wrapper .col:last-child {
    min-width: 14rem;
}

.troop-image-wrapper {
    min-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    cursor: pointer;
}

.troop-name {
    color: burlywood;
    cursor: pointer;
}

.troop-name:hover {
    color: cornflowerblue;
}

.table-header {
    margin: 3rem 0 1rem 0;
    color: lightgoldenrodyellow;
}

.planes-capacity-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 2rem;
}

.planes-table {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 2px;*/
    /*margin-bottom: 2rem;*/
}

.planes-table:deep(.t-col) {
	width: 100%;
}

.troop-image-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    transform: translateY(-0.75rem);
}

.mission-name {
    color: lightgray;
}

.selected-attack {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.city-icon-wrapper {
    width: fit-content;
}

.city-icon {
    height: 2rem;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.25rem;
    flex-wrap: wrap;
}

.city-coords {
    text-wrap: none;
}

.command-target-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 8rem;
}

.command-target {
    color: burlywood;
    cursor: pointer;
}

.command-target:hover {
    color: cornflowerblue;
}

.attacks-dropdown-column,
.mission-planes-dropdown-column {
    position: relative;
}

.attacks-dropdown-wrapper,
.mission-planes-dropdown-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.attacks-dropdown,
.planes-dropdown {
    position: absolute;
    top: 3.5rem;
    right: -1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: black;
    padding: 2px;
    width: max-content;
}

.attacks-dropdown .col:not(.label),
.planes-dropdown .col:not(.label) {
    background: rgba(150, 150, 150, 0.125);
}

.attacks-dropdown .col:last-child {
    width: 4rem;
}

.active-missions-table .col {
    flex-basis: 20%;
}

.active-missions-table .col:first-child {
    flex-basis: 40%;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 80%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
    .planes-capacity-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
        margin: 0 auto;
    }
    .building-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .troop-name {
        display: none;
    }
    .planes-table .col:first-child {
        flex-basis: 6rem;
        justify-content: center;
    }
    .planes-table .col.label:first-child {
        justify-content: flex-start;
    }
    .planes-table .col:not(:first-child) {
        flex-basis: 50%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
