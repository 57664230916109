export default {
    getEmail(state) {
        return state.email;
    },

    getPassword(state) {
        return state.password;
    },

    getUsername(state) {
        return state.username;
    },

    getUniqueEmailBool(state) {
        return state.isUniqueEmail;
    },

    getUniqueUsernameBool(state) {
        return state.isUniqueUsername;
    },

    getAreCredentialsValid(state) {
        return state.areCredentialsValid;
    },

    getAttemptedLogin(state) {
        return state.attemptedLogin;
    },

    getEmailValid(state) {
        return state.emailValid;
    },

    getConfirmAccountResponse(state) {
        return state.confirmAccountResponse;
    },

    getCredentialsRecoveryResponse(state) {
        return state.credentialsRecoveryResponse;
    },

    getChangePasswordResponse(state) {
        return state.changePasswordResponse;
    },

    getSuccessfulLogout(state) {
        return state.successfulLogout;
    },
};
