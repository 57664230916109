export default {
    email: '',
    password: '',
    username: '',
    isUniqueEmail: false,
    isUniqueUsername: false,
    areCredentialsValid: false,
    attemptedLogin: false,
    emailValid: false,
    confirmAccountResponse: undefined,
    credentialsRecoveryResponse: undefined,
    changePasswordResponse: undefined,
    successfulLogout: false,
};
