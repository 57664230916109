import parseTemplateForInitialization from '@/utils/state/parsers/parseTemplateForInitialization';
import { GENERAL_TEMPLATE } from '@/utils/state/templates/generalTemplate';
import { CITY_TEMPLATE } from '@/utils/state/templates/cityTemplate';
import Collection from '@/classes/generic/Collection';

export default {
    userState: parseTemplateForInitialization(GENERAL_TEMPLATE),
    currentCity: parseTemplateForInitialization(CITY_TEMPLATE),
    offers: [],
    awaitingResponse: false,
    allUsers: [],
    countdownStarted: false,
    countdownStartedTroopCommands: false,
    isSubscribed: false,
    mapMatrix: [],
    currentTroop: null,
    newlyReceivedCity: null,
    mapActionTarget: null, // { id: String, name: String, coords: { x: Number, y: Number, } }
    highlightedPins: [],
    selectedActionPin: null, // action id
    mapScaleFactor: 1,

    allMapActions: [],
    currentCityMapActions: {
        cityId: null,
        collection: null,
    },

    mapArrows: new Collection([]),
};
