import state from '@/store/modules/city/state';
import mutations from '@/store/modules/city/mutations';
import actions from '@/store/modules/city/actions';
import getters from '@/store/modules/city/getters';

export default {
    state,
    mutations,
    actions,
    getters,
};
