import Collection from '@/classes/generic/Collection';

export default class MapArrow extends Collection {
    direction = null;

    constructor(actions, direction) {
        super(actions);
        this.direction = direction;
    }

    deleteItem(item) {
        super.deleteItem(item);
        if (this.getItems().size === 0) {
            this.selfDestruct()
        }
    }
}
