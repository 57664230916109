import {
    ActionDescriptionIdentifier,
    ActionDescriptionTargetIdentifier,
    ActionInitiatorIdentifier, ActionsThatAreIntercepts,
    ActionTargetIdentifier
} from "@/utils/constants/actions";
import { reactive } from 'vue';
import CollectionItem from '@/classes/generic/CollectionItem';
import ProgressTracking from '@/classes/mapActions/ProgressTracking';
import Troops from "@/classes/mapActions/Troops";

export default class MapAction extends CollectionItem {
    originalData = null;
    actionType = null;
    isIntercept = false;

    progressTracking = null;
    troops = null;

    initiator = {
        _id: '',
        name: '',
        x: 0,
        y: 0,
    };

    target = {
        _id: '',
        name: '',
        x: 0,
        y: 0,
    };

    actionDescription = '';
    actionDescriptionTarget = '';

    constructor({ action, action_type }) {
        super();

        this.originalData = action;
        this.actionType = action_type;
        this.isIntercept = this.checkIsIntercept(action_type)

        this.progressTracking = reactive(new ProgressTracking(this));
        this.troops = new Troops(this.originalData)

        this.initializeActionInfo();
    }

    checkIsIntercept(action_type) {
        return ActionsThatAreIntercepts.includes(action_type);
    }

    initializeActionInfo() {
        this.initiator = this.getInitiatorInfo();
        this.target = this.getTargetInfo();

        this.actionDescription = this.getActionDescription();
        this.actionDescriptionTarget = this.getActionDescriptionTarget();
    }

    getActionDescription() {
        return ActionDescriptionIdentifier[this.actionType];
    }

    getActionDescriptionTarget() {
        const PropertyToAccess = ActionDescriptionTargetIdentifier[this.actionType];
        return this.originalData[PropertyToAccess]?.name;
    }

    getInitiatorInfo() {
        const PropertyToAccess = ActionInitiatorIdentifier[this.actionType];
        return this.originalData[PropertyToAccess];
    }

    getTargetInfo() {
        const PropertyToAccess = ActionTargetIdentifier[this.actionType];
        return this.originalData[PropertyToAccess];
    }

    startCountdown() {
        this.progressTracking.startProgressUpdate(this);
    }

    stopCountdown() {
        this.progressTracking.stopProgressUpdate();
    }
}
