<template>
	<button
		v-if="isButtonVisible"
		class="btn neutral-button reinforcements-dropdown-button"
		@click="togglePanel"
	>
		<span>&#10095;</span>
	</button>
    <Transition name="blur-transition">
        <div v-if="isPanelVisible && isButtonVisible" class="reinforcements-dropdown-wrapper t-wrapper">
            <div class="t-header t-row">
                <div v-if="isFromUserColumnVisible" class="t-col">From</div>
                <div v-if="isFromCityColumnVisible" class="t-col">City</div>
				<div v-if="isControlTypeColumnVisible" class="t-col">Control</div>
				<div v-if="isSentOriginColumnVisible" class="t-col">Origin</div>
				<div v-if="isReceivedOriginColumnVisible" class="t-col">Origin</div>
				<div v-if="isSpiesOriginColumnVisible" class="t-col">Origin</div>
				<div v-if="isAvailabilityColumnVisible" class="t-col">Available</div>
				<div v-if="isMissionColumnVisible" class="t-col">Mission</div>
                <div class="t-col">Troops</div>
            </div>
            <div v-for="reinforcement in reinforcements" :key="reinforcement.id" class="t-row">
                <div v-if="isFromUserColumnVisible" class="t-col">
                    {{ reinforcement.getLatestOriginUserName() }}
                </div>
                <!-- TO DO: add city image before name -->
                <div v-if="isFromCityColumnVisible" class="t-col">
                    {{ reinforcement.getLatestOriginCityName() }}
                </div>
				<div v-if="isControlTypeColumnVisible" class="t-col">
					{{ reinforcement.getControlLevel() }}
				</div>
				<div v-if="isSentOriginColumnVisible" class="t-col">
					{{ reinforcement.getOriginTypeSent() }}
				</div>
				<div v-if="isReceivedOriginColumnVisible" class="t-col">
					{{ reinforcement.getOriginTypeReceived() }}
				</div>
				<div v-if="isSpiesOriginColumnVisible" class="t-col">
					{{ reinforcement.getOriginType() }}
				</div>
				<div v-if="isAvailabilityColumnVisible" class="t-col" v-html="reinforcement.getAvailability()" />
				<div v-if="isMissionColumnVisible" class="t-col">
					{{ reinforcement.getMission() }}
				</div>
                <div class="t-col">
                    <input
						class="text-input"
                        type="number"
                        min="0"
                        :value="reinforcement.getNumberToSend(commandType)"
                        :max="reinforcement.getTroopCount()"
                        @input="event => setReinforcements(reinforcement.getId(), getInputEventValue(event))"
                    />
                    <span
                        class="clickable overflow-wrap-normal"
                        @click="setReinforcements(reinforcement.getId(), reinforcement.getTroopCount())"
                    >
                        ({{ reinforcement.getTroopCount().toLocaleString('ro') }})
                    </span>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { getInputEventValue } from '@/utils/functions/miscellaneousUtils';

export default {
    name: 'ReinforcementsPanel',
    props: {
		isButtonVisible: {
			type: Boolean,
		},
        reinforcements: {
            type: Set,
            default: new Set(),
        },
        commandType: {
            type: String,
        },
        troopName: {
            type: String,
        },
		isFromUserColumnVisible: {
			type: Boolean,
		},
		isFromCityColumnVisible: {
			type: Boolean,
		},
		isControlTypeColumnVisible: {
			type: Boolean,
		},
		isSentOriginColumnVisible: {
			type: Boolean,
		},
		isReceivedOriginColumnVisible: {
			type: Boolean,
		},
		isSpiesOriginColumnVisible: {
			type: Boolean,
		},
		isAvailabilityColumnVisible: {
			type: Boolean,
		},
		isMissionColumnVisible: {
			type: Boolean,
		},
    },
	data() {
		return {
			isPanelVisible: false,
		};
	},
    methods: {
        getInputEventValue,

        setReinforcements(reinforcement_id, value) {
            this.$store.commit('SET_REINFORCEMENTS_TO_SEND', {
                troop_name: this.troopName,
                reinforcement_id,
                command_type: this.commandType,
                number: value,
            });
        },

		togglePanel() {
			this.isPanelVisible = !this.isPanelVisible;
		},
    },
};
</script>

<style scoped>
.reinforcements-dropdown-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    background: black;
    padding: 2px;
    width: max-content;
    max-width: 90vw;
}

.reinforcements-dropdown-wrapper .t-row:not(.t-header) .t-col {
    background: rgba(150, 150, 150, 0.125);
}

.reinforcements-dropdown-wrapper .t-col:last-child {
    min-width: 10rem;
}

.btn {
	padding: 0.25rem;
}

.neutral-button {
	font-family: 'bebas', sans-serif;
	border-radius: 0;
	background: linear-gradient(to bottom, rgb(30, 30, 30), rgba(255, 255, 255, 0.2));
	border: 3px solid dimgray;
	font-size: 0.9375rem;
	line-height: 0.9375rem;
	color: ghostwhite;
}

.neutral-button:hover {
	background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2), rgb(60, 60, 60));
	color: ghostwhite;
}

.reinforcements-dropdown-button > span {
	writing-mode: vertical-lr;
}
</style>
