import { ReinforcementFilterType, TroopsIdentifier } from "@/utils/constants/troops";
import MapActionsCollection from '@/classes/mapActions/MapActionsCollection';
import { CityPropertiesThatAreActions, ReinforcementCategory } from '@/utils/constants/actions';
import { addActionToMapArrows } from '@/utils/functions/mapArrowsUtils';
import Troop from '@/classes/troops/Troop';
import parseTemplateForInitialization from '@/utils/state/parsers/parseTemplateForInitialization';
import { COALITION_TEMPLATE } from '@/utils/state/templates/coalitionTemplate';
import { CITIES_PER_TILE_HORIZONTALLY, CITIES_PER_TILE_VERTICALLY } from '@/utils/constants/map';
import MapActionFactory from '@/classes/mapActions/MapActionFactory';
import {
    addAdditionalPropertiesToCity,
    addCityToMap,
    getRelevantReceivedReinforcements,
    getRelevantSentReinforcements, getSpiesOnMission
} from "@/store/modules/city/mutations/utils";

const ActionFactory = new MapActionFactory();

export default {
    SET_OWNED_TROOPS_TO_SEND(state, { troop_name, command_type, number }) {
        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        Troop.setNumberToSend(command_type, number);
        console.log('SET_OWNED_TROOPS_TO_SEND', Troop.getNumberToSend(command_type));
    },

    SET_REINFORCEMENTS_TO_SEND(state, { troop_name, reinforcement_id, command_type, number }) {
        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        let Reinforcement;
        Troop.getReinforcements({
            category: ReinforcementCategory.ALL,
            filter_type: null,
            filter_value: null,
        }).forEach(reinforcement => {
            if (reinforcement.getId() === reinforcement_id) {
                Reinforcement = reinforcement;
            }
        });
        Reinforcement.setNumberToSend(command_type, number);
        console.log('SET_REINFORCEMENTS_TO_SEND', Reinforcement.getNumberToSend(command_type));
    },

    SET_ALL_REINFORCEMENTS_TO_SEND(state, { troop_name, command_type, reinforcement_filters }) {
        const { category, filter_type, filter_value } = reinforcement_filters;

        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        Troop.getReinforcements({
            category,
            filter_type,
            filter_value,
        }).forEach(reinforcement => {
            reinforcement.setNumberToSend(command_type, reinforcement.getTroopCount());
        });
        console.log(
            'SET_ALL_REINFORCEMENTS_TO_SEND',
            Troop.getReinforcements({
                category,
                filter_type,
                filter_value,
            }),
        );
    },

    setHighlightedPins(state, pins) {
        state.highlightedPins = pins;
    },

    setSelectedActionPin(state, action_id) {
        state.selectedActionPin = action_id;
    },

    setMapScaleFactor(state, value) {
        state.mapScaleFactor = value;
    },

    incrementMapScaleFactor(state, value) {
        state.mapScaleFactor += value;
    },

    setAwaitingResponse(state, value) {
        state.awaitingResponse = value;
    },

    setUserInfo(state, data) {
        Object.keys(data).forEach(key => {
            if (data[key]) {
                state.userState[key] = data[key];
            }
        });
    },

    setCurrentCity(state, data) {
        // data: { city_id, city_index }

        let CITY_INDEX;

        if (data.city_id) {
            CITY_INDEX = state.userState.cities.findIndex(city => city._id === data.city_id);
        } else {
            CITY_INDEX = data.city_index;
        }

        if (CITY_INDEX !== -1) {
            state.currentCity = state.userState.cities[CITY_INDEX];
            ActionFactory.setCurrentCityId(state.currentCity._id);
            console.log('--- state.currentCity ---', state.currentCity);
        } else {
            console.error('City not found for index:', CITY_INDEX);
        }
    },

    SET_FORMATTED_TROOPS(state) {
        state.userState.cities.forEach(city => {
            city.formattedTroops = [];
            city.troops.forEach(troop => {
                city.formattedTroops.push(
                    new Troop({
                        troop,
                        received_reinforcements: getRelevantReceivedReinforcements({
                            current_city_id: state?.currentCity?._id,
                            troop: troop,
                            reinforcements: city.reinforcements,
                        }),
                        sent_reinforcements: getRelevantSentReinforcements({
                            current_city_id: state?.currentCity?._id,
                            troop: troop,
                            reinforcements: city.reinforcements,
                        }),
                        spies_on_mission: troop.name === TroopsIdentifier.SPY ? getSpiesOnMission(city.foreignSpies) : []
                    }),
                );
            });
            console.log('!@!@!', city.formattedTroops);
        });
    },

    // used for Reinforcements tab in General Office
    SET_SENT_REINFORCEMENTS_BY_CITY(state) {
        const ReinforcementsByCity = [];

        state.currentCity.formattedTroops.forEach(troop => {
            troop
                .getReinforcements({
                    category: ReinforcementCategory.SENT,
                    filter_type: ReinforcementFilterType.NONE,
                    filter_value: null,
                })
                .forEach(reinforcement => {
                    const FoundCityObject = ReinforcementsByCity.find(city => city.id === reinforcement.getDestinationCityId());
                    if (!FoundCityObject) {
                        ReinforcementsByCity.push({
                            id: reinforcement.getDestinationCityId(),
                            troops: new Set([troop]),
                        });
                    } else {
                        if (!FoundCityObject.troops.has(troop)) {
                            FoundCityObject.troops.add(troop);
                        }
                    }
                });
        });

        console.log('SentReinforcementsByCity -->', ReinforcementsByCity);
        state.currentCity.sentReinforcements = ReinforcementsByCity;
    },

    // used for Reinforcements tab in General Office
    SET_RECEIVED_REINFORCEMENTS_BY_CITY(state) {
        const ReinforcementsByCity = [];

        state.currentCity.formattedTroops.forEach(troop => {
            troop
                .getReinforcements({
                    category: ReinforcementCategory.RECEIVED,
                    filter_type: ReinforcementFilterType.NONE,
                    filter_value: null,
                })
                .forEach(reinforcement => {
                    const FoundCityObject = ReinforcementsByCity.find(city => city.id === reinforcement.getLatestOriginCityId());
                    if (!FoundCityObject) {
                        ReinforcementsByCity.push({
                            id: reinforcement.getLatestOriginCityId(),
                            troops: new Set([troop]),
                        });
                    } else {
                        if (!FoundCityObject.troops.has(troop)) {
                            FoundCityObject.troops.add(troop);
                        }
                    }
                });
        });

        console.log('ReceivedReinforcementsByCity -->', ReinforcementsByCity);
        state.currentCity.receivedReinforcements = ReinforcementsByCity;
    },

    UPDATE_TROOP_NUMBERS(state, { command_type, reinforcement_filters }) {
        state.currentCity.formattedTroops.forEach(troop => {
            if (troop.getNumberToSend(command_type) > 0) {
                troop.decreaseTroopCount(troop.getNumberToSend(command_type));
                troop.setNumberToSend(command_type, 0);
            }

            troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
                if (reinforcement.getNumberToSend(command_type) > 0) {
                    reinforcement.decreaseTroopCount(reinforcement.getNumberToSend(command_type));
                    reinforcement.setNumberToSend(command_type, 0);
                }
                if (reinforcement.getTroopCount() === 0) {
                    reinforcement.selfDestruct();
                }
            });
        })
    },

    // SET_SPIES_ON_MISSION_BY_CITY(state) {
    //     const SpiesOnMissionByCity = [];
    //
    //     const Spies = state.currentCity.formattedTroops.find(troop => troop.getName() === TroopsIdentifier.SPY)
    //     Spies.getReinforcements({
    //         category: ReinforcementCategory.SPIES,
    //         filter_type: ReinforcementFilterType.NONE,
    //         filter_value: null,
    //     }).forEach(spies_object => {
    //         const FoundCityObject = SpiesOnMissionByCity.find(city => city.id === spies_object.getDestinationCityId());
    //         if (!FoundCityObject) {
    //             SpiesOnMissionByCity.push({
    //                 id: spies_object.getDestinationCityId(),
    //                 troops: new Set([spies_object]),
    //             });
    //         } else {
    //             if (!FoundCityObject.troops.has(spies_object)) {
    //                 FoundCityObject.troops.add(spies_object);
    //             }
    //         }
    //     })
    //
    //     console.log('SpiesOnMissionByCity -->' , SpiesOnMissionByCity);
    //     state.currentCity.spiesOnMission = SpiesOnMissionByCity;
    // },

    setAllMapActions(state, cities) {
        cities.forEach(city => {
            const CurrentCityActionsObject = {
                cityId: city._id,
                collection: new MapActionsCollection([]),
            };

            Object.values(CityPropertiesThatAreActions).forEach(property => {
                city[property]?.forEach(action => {
                    const Action = ActionFactory.createAction({
                        action_data: action,
                        property_name: property,
                    });
                    if (Action) {
                        CurrentCityActionsObject.collection.addItem(Action);
                    }
                });
            });

            state.allMapActions.push(CurrentCityActionsObject);
        });
    },

    setCurrentCityMapActions(state, data) {
        // data: { city_id, city_index }

        let COLLECTION_INDEX;

        if (data.city_id) {
            COLLECTION_INDEX = state.allMapActions.findIndex(collection => collection.cityId === data.city_id);
        } else {
            COLLECTION_INDEX = data.city_index;
        }

        if (COLLECTION_INDEX !== -1) {
            state.currentCityMapActions = state.allMapActions[COLLECTION_INDEX];
            state.currentCityMapActions.collection.startCountdowns();
        } else {
            console.error('Map action collection not found for index:', COLLECTION_INDEX);
        }

        console.log('>>><<<>><><<', state.currentCityMapActions);
    },

    setAllMapArrows(state) {
        state.allMapActions.forEach(action_group => {
            action_group.collection.getItems().forEach(action => addActionToMapArrows(state.mapArrows, action));
        });

        console.log('@@@@@@@', state.mapArrows);
    },

    setOutsideOffers(state, data) {
        state.offers = [...data];
    },

    lowerResources(state, resources) {
        const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);

        CurrentCity.metal -= resources.metal || 0;
        CurrentCity.aluminum -= resources.aluminum || 0;
        CurrentCity.oil -= resources.oil || 0;
        CurrentCity.rations -= resources.rations || 0;
    },

    increaseResources(state, resources) {
        const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);

        CurrentCity.metal += resources.metal || 0;
        CurrentCity.aluminum += resources.aluminum || 0;
        CurrentCity.oil += resources.oil || 0;
        CurrentCity.rations -= resources.rations || 0;
    },

    setCountdownStarted(state, value) {
        state.countdownStarted = value;
    },

    setCountdownStartedTroopCommands(state, value) {
        state.countdownStartedTroopCommands = value;
    },

    updateDataLive(state, { data }) {
        // "identifier" being null means we need to travel to the object property inside "name"

        // "identifier" not being null means we need to find each array element with property "identifier" === "name"[i];
        // for each array element inside "name" we traverse the rest of the "path" array and apply the provided
        // action with the data in "liveData" obtained by traversing the same paths

        // actions = [
        //     {
        //         path: [
        //             {
        //                 name: 'city',
        //                 identifier: null,
        //             },
        //             {
        //                 name: 'attacks',
        //                 identifier: null,
        //             },
        //             {
        //                 name: ['abc987654321', 'fgh987654321'],
        //                 identifier: '_id',
        //             },
        //             {
        //                 name: 'fromCity',
        //                 identifier: null,
        //             },
        //             {
        //                 name: '_id',
        //                 identifier: null,
        //             },
        //         ],
        //         action: 'replace',
        //     }
        // ]

        console.log(`LIVE DATA: `, JSON.parse(JSON.stringify(data)));

        let { data: parsedData } = data?.data;
        const paths = [];
        const actions = [...parsedData.actions];
        const indexes = [];
        const toReplace = [];

        for (let i = 0; i < parsedData.paths.length; i++) {
            paths.push(parsedData.paths[i].split('.'));
            if (actions[i] === 'remove') {
                toReplace.push(null);
                indexes.push(parsedData.indexes[0]);
                parsedData.indexes.splice(0, 1);
            } else if (actions[i] === 'replacePartial' || actions[i] === 'addPartial' || actions[i] === 'addPartialArray') {
                indexes.push(parsedData.indexes[0]);
                toReplace.push(parsedData.toReplace[0]);
                parsedData.toReplace.splice(0, 1);
                parsedData.indexes.splice(0, 1);
            } else {
                indexes.push(null);
                toReplace.push(null);
            }
        }

        console.log(`indexes: `, indexes);
        console.log(`toReplace: `, toReplace);

        for (let i = 0; i < paths.length; i++) {
            let copyData = structuredClone(parsedData);

            let selector = state.userState;
            let accessFinalPath = true;

            // FOR EACH NESTING WE UPDATE THE SELECTOR AND THE DATA
            for (let j = 0; j < paths[i].length; j++) {
                console.log('\npaths[i]: ', paths[i]);
                console.log('\npaths[i][j]: ', paths[i][j]);

                if (j === paths[i].length - 1) {
                    const testSelector = selector[paths[i][j]];

                    if (
                        typeof testSelector === 'boolean' ||
                        typeof testSelector === 'string' ||
                        typeof testSelector === 'number'
                    ) {
                        accessFinalPath = false;
                    }
                }

                if (accessFinalPath) {
                    selector = selector[paths[i][j]];
                    copyData = copyData[paths[i][j]];
                }

                console.log('\nSELECTOR: ', selector);
                console.log('\ncopyData: ', copyData);

                if (j < paths[i].length - 1) {
                    console.log('\nIF');

                    if (Array.isArray(selector)) {
                        for (let z = 0; z < selector.length; z++) {
                            if (selector[z]._id === copyData[0]._id) {
                                selector = selector[z];
                                break;
                            }
                        }

                        copyData = copyData[0];
                    }
                }
            }

            if (actions[i] === 'push') {
                selector.push(copyData[0]);
                Object.values(CityPropertiesThatAreActions).forEach(property => {
                    if (paths[i].includes(property)) {
                        const ReceivedAction = copyData[0];
                        const Action = ActionFactory.createAction({
                            action_data: ReceivedAction,
                            property_name: property,
                        });

                        if (Action) {
                            state.currentCityMapActions.collection.addItem(Action);
                            addActionToMapArrows(state.mapArrows, Action);
                            Action.startCountdown();

                            console.log('@@@@@@@ live', state.mapArrows);
                        }
                    }
                });
            } else if (actions[i] === 'remove') {
                if (indexes[i] !== 'none') {
                    // TO DO: search for way to not use multiIndexes
                    const multiIndexes = indexes[i].split('.');
                    let multiIndex = false;

                    if (multiIndexes.length > 1) {
                        multiIndex = true;
                    }

                    for (let j = 0; j < selector.length; j++) {
                        if (multiIndex) {
                            let temporarySelector = selector[j];
                            let temporaryCopyData = copyData[0];

                            for (let z = 0; z < multiIndexes.length; z++) {
                                temporarySelector = temporarySelector[multiIndexes[z]];
                                temporaryCopyData = temporaryCopyData[multiIndexes[z]];
                            }

                            if (temporarySelector === temporaryCopyData) {
                                selector.splice(j, 1);
                                break;
                            }
                        } else {
                            if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                                selector.splice(j, 1);
                                break;
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < selector.length; j++) {
                        if (selector[j] === copyData[0]) {
                            selector.splice(j, 1);
                            break;
                        }
                    }
                }
            } else if (actions[i] === 'removeMany') {
                for (let j = 0; j < selector.length; j++) {
                    for (let z = 0; z < copyData.length; z++) {
                        if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                            selector.splice(j, 1);
                            copyData.splice(z, 1);
                            j--;
                            break;
                        }
                    }
                }
            } else if (actions[i] === 'replacePartial') {
                for (let j = 0; j < selector.length; j++) {
                    if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                        selector[j][toReplace[i]] = copyData[0][toReplace[i]];
                        break;
                    }
                }
            } else if (actions[i] === 'replacePartialArray') {
                for (let j = 0; j < selector.length; j++) {
                    for (let z = 0; z < copyData.length; z++) {
                        if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                            selector[j][toReplace[i]] = copyData[z][toReplace[i]];
                            break;
                        }
                    }
                }
            } else if (actions[i] === 'replace') {
                if (paths[i][0] === 'coalition' && paths[i].length === 1 && !copyData) {
                    Object.assign(selector, parseTemplateForInitialization(COALITION_TEMPLATE));
                } else if (!accessFinalPath) {
                    selector[paths[i][paths[i].length - 1]] = copyData[paths[i][paths[i].length - 1]];
                } else {
                    Object.assign(selector, copyData);
                }
            } else if (actions[i] === 'add') {
                selector += copyData;
            } else if (actions[i] === 'substract') {
                selector[paths[i][paths[i].length - 1]] -= copyData[paths[i][paths[i].length - 1]];
            } else if (actions[i] === 'substractPartial') {
                for (let j = 0; j < selector.length; j++) {
                    if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                        selector[j][toReplace[i]] -= copyData[0][toReplace[i]];
                        break;
                    }
                }
            } else if (actions[i] === 'addPartial') {
                for (let j = 0; j < selector.length; j++) {
                    if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                        selector[j][toReplace[i]] += copyData[0][toReplace[i]];
                        break;
                    }
                }
            } else if (actions[i] === 'addPartialArray') {
                for (let j = 0; j < selector.length; j++) {
                    for (let z = 0; z < copyData.length; z++) {
                        if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                            selector[j][toReplace[i]] += copyData[z][toReplace[i]];
                            break;
                        }
                    }
                }
            }
        }
    },

    updateOffersLive(state, { data }) {
        const LiveOffer = data.data.offer;
        console.log('OFFERS DATA:', LiveOffer);
        if (data.data.offer.actions.includes('push')) {
            state.offers.push(LiveOffer);
        }
        if (data.data.offer.actions.includes('remove')) {
            const OfferInState = state.offers.find(offer => offer._id === LiveOffer._id);
            OfferInState.toBeRemoved = true;
            OfferInState.repeats = 0;
        }
        if (data.data.offer.actions.includes('replacePartial')) {
            const OfferInState = state.offers.find(offer => offer._id === LiveOffer._id);
            LiveOffer.toReplace.forEach(property => (OfferInState[property] = LiveOffer[property]));
            OfferInState.newValue = true;
            setTimeout(() => {
                OfferInState.newValue = false;
            }, 2000);
        }
    },

    updateGameDataLive(state, { data }) {
        console.log(`UPDATE GAME DATA LIVE: `, data);
        data.data.gameData.forEach(live_user => {
            const FoundUser = state.allUsers.find(state_user => state_user._id === live_user._id);
            if (live_user.actions.includes('remove')) {
                live_user.cities.forEach(live_city => {
                    if (FoundUser.cities.length <= 1) {
                        const FoundUserIndex = state.allUsers.findIndex(state_user => state_user._id === live_user._id);
                        state.allUsers.splice(FoundUserIndex, 1);
                    } else {
                        const FoundCityIndex = FoundUser.cities.findIndex(state_city => state_city._id === live_city._id);
                        FoundUser.cities.splice(FoundCityIndex, 1);
                    }
                });
            }
            if (live_user.actions.includes('push')) {
                live_user.cities.forEach(live_city => {
                    addAdditionalPropertiesToCity({ state, user: live_user, city: live_city });
                    addCityToMap({ state, city: live_city });
                    state.newlyReceivedCity = live_city;
                });
                if (!FoundUser) {
                    state.allUsers.push(live_user);
                } else {
                    live_user.cities.forEach(live_city => {
                        FoundUser.cities.push(live_city);
                    });
                }
            }
        });
    },

    removeInactiveOffers(state) {
        state.offers = state.offers.filter(offer => !offer.toBeRemoved);
    },

    removeOffer(state, offer_id) {
        const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);
        CurrentCity.offers = CurrentCity.offers.filter(offer => offer._id !== offer_id);
    },

    updateSubscriptions(state) {
        state.isSubscribed = true;
    },

    updatePactInvitations(state, data) {
        const PACT_INVITATION_INDEX = state.userState.coalition.pactInvitations.findIndex(invitation => invitation.id === data);
        if (PACT_INVITATION_INDEX !== -1) {
            state.userState.coalition.pactInvitations.splice(PACT_INVITATION_INDEX, 1);
        }
    },

    updateMemberRank(state, data) {
        // data: id, rank
        const MEMBER = state.userState.coalition.rights.find(member => member.user === data.id);
        MEMBER.rank = data.rank;
    },

    updateMemberRights(state, data) {
        // data: ids, permissions
        data.ids.forEach((id, index) => {
            const MEMBER = state.userState.coalition.rights.find(member => member.user === id);
            MEMBER.permissions.forEach(permission_to_change => {
                data.permissions[index].forEach(new_permission => {
                    if (permission_to_change.permission === new_permission.permission) {
                        permission_to_change.hasIt = new_permission.hasIt;
                    }
                });
            });
        });
    },

    updateMembers(state, data) {
        // data: member_to_add (to be handled later), member_to_remove
        const ALL_MEMBERS_ENTRY_INDEX = state.userState.coalition.allMembers.findIndex(
            member => member.memberId === data.member_to_remove,
        );
        state.userState.coalition.allMembers.splice(ALL_MEMBERS_ENTRY_INDEX, 1);

        const RIGHTS_ENTRY_INDEX = state.userState.coalition.rights.findIndex(member => member.user === data.member_to_remove);
        state.userState.coalition.rights.splice(RIGHTS_ENTRY_INDEX, 1);
    },

    setMapMatrix(state, matrix) {
        // TO DO: move to city constants
        const CityLevels = [
            [0, 500],
            [501, 1500],
            [1501, 2500],
            [2501, 9999999999],
        ];

        matrix.forEach((row, row_index) => {
            row.forEach((tile, tile_index) => {
                tile.x = tile_index;
                tile.y = row_index;

                if (!tile.cities) {
                    tile.cities = [];
                }
                if (!tile.decorations) {
                    tile.decorations = [];
                }

                tile.cities.forEach((city, city_index) => {
                    state.allUsers.forEach(user => {
                        user.cities.forEach(user_city => {
                            if (city._id === user_city._id) {
                                user_city.localX = city.x % CITIES_PER_TILE_HORIZONTALLY;
                                user_city.localY = city.y % CITIES_PER_TILE_VERTICALLY;
                                // TO DO: extract util
                                user_city.level =
                                    CityLevels.findIndex(range => user_city.points >= range[0] && user_city.points <= range[1]) +
                                    1;
                                user_city.isBarbarian = user.isBarbarian;
                                // TO DO: also add map/minimap color to city object

                                tile.cities[city_index] = user_city;
                            }
                        });
                    });
                });

                tile.decorations.forEach(decoration => {
                    decoration.localX = decoration.x % CITIES_PER_TILE_HORIZONTALLY;
                    decoration.localY = decoration.y % CITIES_PER_TILE_VERTICALLY;
                });
            });
        });

        state.mapMatrix = matrix;
        console.log('*****', state.mapMatrix);
    },

    SET_CURRENT_TROOP(state, troop_name) {
        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        state.currentTroop = Troop;
    },

    setNewlyReceivedCity(state, data) {
        state.newlyReceivedCity = data;
    },

    setAllUsers(state, data) {
        state.allUsers = data;
        console.log('>> All Users >>', state.allUsers);
    },

    setMapActionTarget(state, data) {
        state.mapActionTarget = data;
    },
    updateOwnedTroopsNumber(state, data) {
        // data: troop_name, number
        const INDEX = state.currentCity.troops.findIndex(troop => troop.name === data.troop_name);
        state.currentCity.troops[INDEX].numOfTroops -= data.number;
    },
    updateControlledTroopsNumber(state, data) {
        // data: troop_name, number
        const INDEX = state.currentCity.troops.findIndex(troop => troop.name === data.troop_name);
        state.currentCity.troops[INDEX].controlledReinforcements -= data.number;
    },
    updateReinforcementNumber(state, data) {
        // data: troop_name, reinforcement_id, number
        const REINFORCEMENT_INDEX = state.currentCity.reinforcements.findIndex(
            reinforcement => reinforcement._id === data.reinforcement_id,
        );
        const TROOP_INDEX = state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities.findIndex(
            troop => troop.troopName === data.troop_name,
        );

        if (
            parseInt(state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities[TROOP_INDEX].numOfTroops, 10) ===
            parseInt(data.number, 10)
        ) {
            state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities.splice(TROOP_INDEX, 1);
            return;
        }
        state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities[TROOP_INDEX].numOfTroops -= data.number;
    },
    updateOwnedForeignSpiesNumber(state, data) {
        // data: city_id, number
        const SPIES_INDEX = state.currentCity.foreignSpies.findIndex(spies => spies.toCity?._id === data.city_id);
        const SPIES_GROUP_INDEX = state.currentCity.foreignSpies[SPIES_INDEX].troops.findIndex(spies => spies.owned);
        state.currentCity.foreignSpies[SPIES_INDEX].troops[SPIES_GROUP_INDEX].numOfTroops -= data.number;

        // delete infiltrated spies row if no spies remaining
        if (state.currentCity.foreignSpies[SPIES_INDEX].troops.find(spies => spies.numOfTroops > 0)) {
            return;
        }
        state.currentCity.foreignSpies.splice(SPIES_INDEX, 1);
    },
    updateControlledForeignSpiesNumber(state, data) {
        // data: city_id, reinforcement_id, number
        const SPIES_INDEX = state.currentCity.foreignSpies.findIndex(spies => spies.toCity?._id === data.city_id);
        const SPIES_GROUP_INDEX = state.currentCity.foreignSpies[SPIES_INDEX].troops.findIndex(
            spies => spies.reinforcement === data.reinforcement_id,
        );
        if (
            parseInt(state.currentCity.foreignSpies[SPIES_INDEX].troops[SPIES_GROUP_INDEX].numOfTroops, 10) ===
            parseInt(data.number, 10)
        ) {
            state.currentCity.foreignSpies[SPIES_INDEX].troops.splice(SPIES_GROUP_INDEX, 1);
            // delete infiltrated spies row if no spies remaining
            if (state.currentCity.foreignSpies[SPIES_INDEX].troops.find(spies => spies.numOfTroops > 0)) {
                return;
            }
            state.currentCity.foreignSpies.splice(SPIES_INDEX, 1);
            return;
        }
        state.currentCity.foreignSpies[SPIES_INDEX].troops[SPIES_GROUP_INDEX].numOfTroops -= data.number;
    },
    removeWarEffects(state, { war_id }) {
        const WAR = state.userState.coalition.wars.find(war => war._id === war_id);
        WAR.effectsInitiator = [];
        WAR.effectsTarget = [];
    },
};
