import { CITIES_PER_TILE_HORIZONTALLY, CITIES_PER_TILE_VERTICALLY } from "@/utils/constants/map";
import { getCityLevel } from "@/utils/functions/cityUtils";
import { CityColorLabels } from "@/utils/constants/city";
import Reinforcement from "@/classes/troops/Reinforcement";
import SpiesOnMission from "@/classes/troops/SpiesOnMission";

export function getCityMapColor({ state, user, city }) {
    if (user.isBarbarian) {
        return CityColorLabels.BARBARIAN;
    }
    const isOwnCity = user._id === state.userState._id;
    if (isOwnCity) {
        const isCurrentCity = city._id === state.currentCity._id;
        if (isCurrentCity) {
            return CityColorLabels.CURRENT_CITY;
        } else {
            return CityColorLabels.OWNED_CITY;
        }
    } else {
        const isUserFriend = state.userState.friends.find(friend => friend === user._id);
        if (isUserFriend) {
            return CityColorLabels.FRIEND;
        }

        const isUserInSameCoalition = state.userState.coalition?.allMembers.find(member => member._id === user._id);
        if (isUserInSameCoalition) {
            return CityColorLabels.COALITION_MEMBER;
        }

        const isUserAnAlly = state.userState.coalition?.alliances.find(ally => ally._id === user.coalition?._id);
        if (isUserAnAlly) {
            return CityColorLabels.ALLY;
        }

        const isUserInNap = state.userState.coalition?.nonAggressionPacts.find(
            nap_coalition => nap_coalition._id === user.coalition?._id,
        );
        if (isUserInNap) {
            return CityColorLabels.NAP;
        }

        const isUserAnEnemy = state.userState.coalition?.wars.find(
            war => war.initiatorCoalition._id === user.coalition?._id || war.targetCoalition._id === user.coalition?._id,
        );
        if (isUserAnEnemy) {
            return CityColorLabels.ENEMY;
        }

        return CityColorLabels.OTHER;
    }
}

export function addAdditionalPropertiesToCity({ state, user, city }) {
    city.color = getCityMapColor({ state, user, city });
    city.localX = city.x % CITIES_PER_TILE_HORIZONTALLY;
    city.localY = city.y % CITIES_PER_TILE_VERTICALLY;

    city.level = getCityLevel(city.points);
    city.isBarbarian = user.isBarbarian;
}

export function addCityToMap({ state, city }) {
    const TileX = Math.floor(city.x / CITIES_PER_TILE_HORIZONTALLY);
    const TileY = Math.floor(city.y / CITIES_PER_TILE_VERTICALLY);

    const FoundTile = state.mapMatrix[TileY][TileX];
    FoundTile.cities.push(city);
}

function isRelevantReceivedReinforcement(reinforcement, current_city_id) {
    return reinforcement?.toCity?._id === current_city_id && reinforcement?.arrived;
}

function isRelevantSentReinforcement(reinforcement, current_city_id) {
    return reinforcement?.toCity?._id !== current_city_id && reinforcement?.arrived;
}

// troop_portion is an element in reinforcement.troopsInCities array
function isReinforcementPortionInCurrentCity(reinforcement_portion, current_city_id) {
    // return reinforcement?.troopsInCities?.currentCity?.city !== current_city_id && reinforcement?.toCity?._id !== current_city_id;
    return reinforcement_portion?.currentCity?._id === current_city_id;
}

export function getRelevantReceivedReinforcements({ current_city_id, troop, reinforcements }) {
    const RelevantReinforcements = [];
    reinforcements.forEach(reinforcement => {
        if (!isRelevantReceivedReinforcement(reinforcement, current_city_id)) {
            return;
        }
        reinforcement.troopsInCities.forEach(troop_object => {
            if (
                isReinforcementPortionInCurrentCity(troop_object, current_city_id) &&
                troop_object.troopName === troop.name
            ) {
                RelevantReinforcements.push(new Reinforcement(reinforcement, troop_object));
            }
        });
    });
    return RelevantReinforcements;
}
export function getRelevantSentReinforcements({ current_city_id ,troop, reinforcements }) {
    const RelevantReinforcements = [];
    reinforcements.forEach(reinforcement => {
        if (!isRelevantSentReinforcement(reinforcement, current_city_id)) {
            return;
        }
        reinforcement.troopsInCities.forEach(troop_object => {
            if (troop_object.troopName === troop.name) {
                RelevantReinforcements.push(new Reinforcement(reinforcement, troop_object));
            }
        });
    });
    return RelevantReinforcements;
}

export function getSpiesOnMission(foreign_spies) {
    const Spies = []
    // each object in the foreignSpies array represents a city
    // all spies sent to that city are collected in the respective object
    foreign_spies.forEach(city_object => {
        city_object.troops.forEach(spies_object => {
            Spies.push(new SpiesOnMission(city_object, spies_object))
        })
    })
    return Spies;
}
