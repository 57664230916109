import ToSendOnAction from '@/classes/troops/ToSendOnAction';
import CollectionItem from '@/classes/generic/CollectionItem';
import { v4 as uuidv4 } from 'uuid';

export default class Reinforcement extends CollectionItem {
    _originalReinforcement = null;
    _originalTroopsInCitiesObject = null;

    _id = null;
    _toSend = new ToSendOnAction();

    constructor(reinforcement, troop_object) {
        super();
        this._originalReinforcement = reinforcement;
        this._originalTroopsInCitiesObject = troop_object;
        this._id = uuidv4();
    }

    getId() {
        return this._id;
    }

    getOriginalId() {
        return this._originalReinforcement?._id;
    }

    getLatestOriginUserName() {
        return this._originalReinforcement?.fromUser?.username;
    }

    getLatestOriginCityName() {
        return this._originalReinforcement?.fromCity?.name;
    }

    getLatestOriginCityId() {
        return this._originalReinforcement?.fromCity?._id;
    }

    getEarliestOriginCityId() {
        return this._originalTroopsInCitiesObject?.pastCities[0]?._id;
    }

    getEarliestOriginCityName() {
        return this._originalTroopsInCitiesObject?.pastCities[0]?.name;
    }

    getEarliestOriginUserId() {
        return this._originalTroopsInCitiesObject?.pastCities[0]?.userid;
    }

    getEarliestOriginUserName() {
        return this._originalTroopsInCitiesObject?.pastCities[0]?.username;
    }

    getDestinationUserName() {
        return this._originalReinforcement?.toUser?.username;
    }

    getDestinationCityId() {
        return this._originalReinforcement?.toCity?._id;
    }

    getDestinationCityName() {
        return this._originalReinforcement?.toCity?.name;
    }

    getTroopName() {
        return this._originalTroopsInCitiesObject?.troopName;
    }

    getTroopCount() {
        return this._originalTroopsInCitiesObject?.numOfTroops;
    }

    decreaseTroopCount(number) {
        this._originalTroopsInCitiesObject.numOfTroops -= number;
    }

    increaseTroopCount(number) {
        this._originalTroopsInCitiesObject.numOfTroops += number;
    }

    getNumberToSend(command_type) {
        return this._toSend[command_type];
    }

    setNumberToSend(command_type, number) {
        this._toSend[command_type] = number;
    }

    // false === cannot be sent on any mission
    isUsable() {
        return this._originalReinforcement?.usable === true;
    }

    // cannot be sent on any mission
    isStationed() {
        return this._originalReinforcement?.targetCanControl === false;
    }

    // can be sent on missions, except Reinforcement
    isPartiallyControlled() {
        return (
            this._originalReinforcement?.targetCanControl === true && this._originalReinforcement?.targetCanPassControl === false
        );
    }

    // can be sent on ANY mission
    isFullyControlled() {
        return (
            this._originalReinforcement?.targetCanControl === true && this._originalReinforcement?.targetCanPassControl === true
        );
    }

    getControlLevel() {
        if (this.isStationed()) {
            return 'Stationed';
        }
        if (this.isPartiallyControlled()) {
            return 'Partial control';
        }
        if (this.isFullyControlled()) {
            return 'Full control';
        }
    }

    // only relevant for sent reinforcements
    getOriginTypeSent() {
        if (this.getEarliestOriginCityId() === this.getDestinationCityId()) {
            return 'Owned';
        } else {
            return 'Controlled';
        }
    }

    // only relevant for received reinforcements
    getOriginTypeReceived() {
        if (this.getEarliestOriginCityId() === this.getLatestOriginCityId()) {
            return 'Owned';
        } else {
            return 'Controlled';
        }
    }

    getAvailability() {
        return this._originalTroopsInCitiesObject?.arrived ? '&#10003;' : '&#10007;';
    }

    getMission() {
        return this._originalTroopsInCitiesObject?.mission;
    }

    getCurrentCityId() {
        return this._originalTroopsInCitiesObject?.currentCity?._id;
    }

    isInCity(city_id) {
        return this.getCurrentCityId() === city_id;
    }

    isOriginallyFromCity(city_id) {
        return this.getEarliestOriginCityId() === city_id;
    }

    isFromCity(city_id) {
        return this.getLatestOriginCityId() === city_id;
    }

    isSentToCity(city_id) {
        return this.getDestinationCityId() === city_id;
    }
}
